import api from "../utils/API";

// Base URL for your API
export const specificationService = {
  fetchSpecificationsList: async (payload) => {
    const response = await api.get("/admin/specification", { params: payload });
    return response.data;
  },
  fetchSpecificationById: async (id) => {
    const response = await api.get(`/admin/specification/${id}`);
    return response.data;
  },
  createSpecifications: async (payload) => {
    const response = await api.post("/admin/specification", payload, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  },
  updateSpecifications: async (id, payload) => {
    const response = await api.put(`/admin/specification/${id}`, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  },
  deleteSpecifications: async (payload) => {
    const response = await api.delete("/admin/specification/delete", {
      data: payload,
    });
    return response.data;
  },
};
