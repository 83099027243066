import { createSlice } from "@reduxjs/toolkit";

// Create a slice for categories
const categorySlice = createSlice({
  name: "category",
  initialState: {
    items: [],
    setCategory: {},
    loading: false,
    error: null,
  },
  reducers: {
    setCategories: (state, action) => {
      state.items = action.payload.data || [];
      state.loading = false;
    },
    setCategory: (state, action) => {
      state.category = action.payload.data || [];
      state.loading = false;
    },
  },
});

// Export the selector to access category data
export const selectCategories = (state) => state.category.items;
export const selectCategory = (state) => state.category.category;
// Export the loading and error selectors if needed
export const selectCategoryLoading = (state) => state.category.loading;
export const selectCategoryError = (state) => state.category.error;

// Export the reducer to include in the store
export const {
  setCategories,
  setCategory,
  setMenuItems,
} = categorySlice.actions;
export default categorySlice.reducer;
