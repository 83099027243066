
import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Avatar,
  Button,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Sample data for the chart
const chartData = [
  { name: '12:00 AM', value: 40 },
  { name: '01:00 AM', value: 30 } ,
  { name: '02:00 AM', value: 45 },
  { name: '03:00 AM', value: 25 },
  { name: '04:00 AM', value: 35 },
  { name: '05:00 AM', value: 50 },
  { name: '06:00 AM', value: 30 },
  { name: '07:00 AM', value: 40 },
  { name: '08:00 AM', value: 35 },
  { name: '09:00 AM', value: 45 },
  { name: '10:00 AM', value: 40 },
  { name: '11:00 AM', value: 120 },
  { name: '12:00 PM', value: 45 },
  { name: '01:00 PM', value: 30 } ,
  { name: '02:00 PM', value: 45 },
  { name: '03:00 PM', value: 25 },
  { name: '04:00 PM', value: 35 },
  { name: '05:00 PM', value: 50 },
  { name: '06:00 PM', value: 30 },
  { name: '07:00 PM', value: 150 },
  { name: '08:00 PM', value: 35 },
  { name: '09:00 PM', value: 45 },
  { name: '10:00 PM', value: 19 },
  { name: '11:00 PM', value: 30 }  
];

// Sample data for top customers
const topCustomers = [
  { id: 1, name: 'Atif Siddiq', avatar: '/placeholder.svg' },
  { id: 2, name: 'Saad Barkat', avatar: '/placeholder.svg' },
  { id: 3, name: 'Masood Al-Amir', avatar: '/placeholder.svg' },
  { id: 4, name: 'Emaam Wasim', avatar: '/placeholder.svg' },
  { id: 5, name: 'Amir Khan', avatar: '/placeholder.svg' },
];

// Sample data for recent ads
const recentAds = [
  {
    id: 'S445467',
    title: '2024 Kia Seltos for Sale',
    user: 'Roan',
    status: 'Live',
    viewPeriod: '07 Aug-24',
    category: 'Cars',
  },
  {
    id: '5258745',
    title: 'BMW M5 electric Semi NEW2',
    user: 'Sofia',
    status: 'Live',
    viewPeriod: '07 Aug-24',
    category: 'Classic Cars',
  },
  // Add more sample data as needed
];

export default function Dashboard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [chartPeriod, setChartPeriod] = useState(0);

  const handleChartPeriodChange = (event,newValue) => {
    setChartPeriod(newValue);
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* Header */}
      <Paper> 
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems:'center', mb: 4 ,p:2 }}>
            <Typography variant="h4">Dashboard</Typography>
            <Typography variant="subtitle1" color="text.secondary">
            Welcome Admin
            </Typography>
        </Box>
      </Paper>

      {/* Stats Cards */}
      <Paper> 
      <Grid container spacing={2} sx={{ mb: 4,p:2 }}>
        <Grid item xs={6} md={3}>
          <Card sx={{ bgcolor: '#F06B5B' }}>
            <CardContent>
              <Typography variant="subtitle2" color="white">
                Total Ads
              </Typography>
              <Typography variant="h4" color="white">
                953
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} md={3}>
          <Card sx={{ bgcolor: '#F4A261' }}>
            <CardContent>
              <Typography variant="subtitle2" color="white">
                Total Revenue (KWD)
              </Typography>
              <Typography variant="h4" color="white">
                4,685
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} md={3}>
          <Card sx={{ bgcolor: '#E9C46A' }}>
            <CardContent>
              <Typography variant="subtitle2" color="white">
                Total Users
              </Typography>
              <Typography variant="h4" color="white">
                152
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} md={3}>
          <Card sx={{ bgcolor: '#2A9D8F' }}>
            <CardContent>
              <Typography variant="subtitle2" color="white">
                Total Active Ads
              </Typography>
              <Typography variant="h4" color="white">
                76
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      </Paper>
      {/* Chart Section */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 2 }}>
            <Tabs value={chartPeriod} onChange={handleChartPeriodChange} sx={{ mb: 2 }}>
              <Tab label="Daily" />
              <Tab label="Weekly" />
              <Tab label="Monthly" />
            </Tabs>
            <Box sx={{ height: 300 }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="value" fill="#F06B5B" />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>

        {/* Top Customers */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Top Customers
            </Typography>
            <List>
              {topCustomers.map((customer) => (
                <ListItem key={customer.id}>
                  <ListItemAvatar>
                    <Avatar src={customer.avatar} />
                  </ListItemAvatar>
                  <ListItemText primary={customer.name} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end">
                      <ChevronRightIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>

      {/* Recent Ads */}
      <Box sx={{ mt: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">Recent Ads</Typography>
          <Button
            variant="contained"
            color="primary"
            endIcon={<ArrowForwardIcon />}
          >
            Go to Ads Management
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ bgcolor: '#F06B5B' }}>
              <TableRow>
                <TableCell sx={{ color: 'white' }}>Ad Title</TableCell>
                <TableCell sx={{ color: 'white' }}>ID</TableCell>
                <TableCell sx={{ color: 'white' }}>User</TableCell>
                <TableCell sx={{ color: 'white' }}>Status</TableCell>
                <TableCell sx={{ color: 'white' }}>View Period</TableCell>
                <TableCell sx={{ color: 'white' }}>Category</TableCell>
                <TableCell sx={{ color: 'white' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recentAds.map((ad) => (
                <TableRow key={ad.id}>
                  <TableCell>{ad.title}</TableCell>
                  <TableCell>{ad.id}</TableCell>
                  <TableCell>{ad.user}</TableCell>
                  <TableCell>
                    <Chip
                      label={ad.status}
                      color={ad.status === 'Live' ? 'success' : 'default'}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>{ad.viewPeriod}</TableCell>
                  <TableCell>{ad.category}</TableCell>
                  <TableCell>
                    <Button size="small" variant="outlined">
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}