import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import authReducer from "./authSlice";
import categoryReducer from "./categorySlice";
import specificationReducer from "./specificationSlice";
import additionalDetailsReducer from "./additionalDetailsSlice";
import categoryMenuReducer from "./menuSlice";
import reportReducer from "./reportSlice";
import adReducer from "./adSlice";
import configReducer from "./configSlice";
import createPostReducer from "./createPostSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    auth: authReducer,
    category: categoryReducer,
    specification: specificationReducer,
    additionaldetails: additionalDetailsReducer,
    categoryMenu: categoryMenuReducer,
    report: reportReducer,
    ad: adReducer,
    config: configReducer,
    createPost: createPostReducer,
  },
});
