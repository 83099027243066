import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCategories,
  selectCategoryError,
  setCategories,
  setCategory,
} from "../store/categorySlice";
import { categoryService } from "../services/category.api";

const useCategory = () => {
  const dispatch = useDispatch();
  const categories = useSelector(selectCategories);
  const category = useSelector((state) => state.category.category);
  const error = useSelector(selectCategoryError);
  const [loading, setLoading] = useState(false);

  const fetchCategories = async (payload) => {
    setLoading(true);
    const data = await categoryService.fetchCategories(payload);
    if (data.success) {
      dispatch(setCategories(data));
    }
    setLoading(false);
    return data;
  };

  const createCategory = async (payload) => {
    setLoading(true);
    const data = await categoryService.createCategory(payload);
    setLoading(false);
    return data;
  };

  const updateCategory = async (id, payload) => {
    setLoading(true);
    const data = await categoryService.updateCategory(id, payload);
    setLoading(false);
    return data;
  };

  const deleteCategory = async (payload) => {
    setLoading(true);
    const data = await categoryService.deleteCategory(payload);
    setLoading(false);
    return data;
  };

  const getCategoryBuId = async (payload) => {
    setLoading(true);
    const data = await categoryService.getCategoryById(payload);
    if (data.success) {
      dispatch(setCategory(data));
    }
    setLoading(false);
    return data;
  };

  return {
    fetchCategories,
    categories,
    category,
    getCategoryBuId,
    createCategory,
    updateCategory,
    deleteCategory,
    loading,
    error,
  };
};

export default useCategory;
