export const formatTimestamp = (timestamp, isTime = true) => {
  const date = new Date(timestamp);

  const dateOptions = {
    day: "2-digit",
    month: "short",
    year: "numeric",
  };

  const timeOptions = {
    ...dateOptions,
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const options = isTime ? timeOptions : dateOptions;
  return date.toLocaleString("en-GB", options).replace(",", "");
};
