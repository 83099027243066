

import { useState, useRef, useEffect } from 'react'
import {
    Box,
    TextField,
    IconButton,
    Typography,
    Avatar,
    InputAdornment,
    Paper,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    CircularProgress,
    Container,
    Button,
    useMediaQuery,
    Badge,
} from '@mui/material'
import { ArrowBackIos } from '@mui/icons-material'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import EmojiPicker from 'emoji-picker-react';
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import EmptyChatPlaceholder from '../../components/chat/EmptyChatPlaceholder';
import { useChat } from '../../hooks/useChat';
import useAuth from '../../hooks/useAuth';
import { useSocket } from '../../context/SocketContext';

const SearchIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
    </svg>
)

const SendIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
    </svg>
)



export default function ChatInterface() {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const socket = useSocket();
    const { user } = useAuth();
    const { id } = useParams();
    const location = useLocation();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm')); // Detect mobile devices

    const {
        conversations,
        messages,
        selectedChat,
        currentMessagesPage,
        totalMessagesPages,
        setSelectedChat,
        fetchConversationsData,
        fetchMessagesData,
        handleSendMessage,
        setMessage,
        message,
        resetChat,
        unreadCounts,
        isTyping,
        lastMessages,
    } = useChat(socket, user); // Using custom hook


    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [loading, setLoading] = useState(false); // Loader state for fetching messages
    const scrollRef = useRef(); // Ref for the scrollable container
    const isAtBottomRef = useRef(true); // Tracks if user is at the bottom of the scroll area
    const observer = useRef(); // Ref for the intersection observer
    const [conversationPage, setConversationPage] = useState(1)
    const conversationsContainerRef = useRef(null)

    // Fetch conversations on component mount
    useEffect(() => {
        fetchConversationsData();
    }, [fetchConversationsData]);


    // Select chat when chat ID is present in URL
    useEffect(() => {
        if (id) {
            resetChat()
            setLoading(true);
            const chat = conversations.find(c => c._id === id);
            if (chat) {
                setSelectedChat(chat);
                fetchMessagesData(chat._id, 1); // Fetch initial messages
            }
            setLoading(false);

        }
    }, [id, conversations]);


    const scrollToBottom = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    };

    // Scroll to the bottom on initial load
    useEffect(() => {
        scrollToBottom();
    }, []);

    // Scroll to bottom if a new message is added and the user is at the bottom
    useEffect(() => {
        if (isAtBottomRef.current) {
            scrollToBottom();
        }
    }, [messages]);



    // Function to handle loading more messages when the last message comes into view
    const loadMoreMessages = async () => {
        setLoading(true);
        if (currentMessagesPage !== 0) {
            await fetchMessagesData(selectedChat._id, currentMessagesPage + 1);
        }
        setLoading(false);
    };

    // Observer for infinite scrolling
    useEffect(() => {
        const handleScroll = () => {
            const { scrollTop, clientHeight, scrollHeight } = scrollRef.current;
            isAtBottomRef.current = scrollTop + clientHeight >= scrollHeight - 10; // Close to bottom threshold
        };

        const lastMessageElement = scrollRef.current?.lastElementChild;

        if (observer.current) {
            observer.current.disconnect(); // Clean up previous observer
        }

        if (lastMessageElement) {
            observer.current = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting && !loading && currentMessagesPage < totalMessagesPages) {
                        loadMoreMessages();
                    }
                },
                { threshold: 1.0 }
            );
            observer.current.observe(lastMessageElement);
        }

        // Attach scroll handler
        scrollRef?.current?.addEventListener('scroll', handleScroll);

        return () => {
            if (observer.current) observer.current.disconnect();
            scrollRef?.current?.removeEventListener('scroll', handleScroll);
        };
    }, [messages, loading, currentMessagesPage, totalMessagesPages]);


    // Handle conversation list scroll
    const handleConversationScroll = () => {
        const container = conversationsContainerRef.current
        if (container) {
            if (container.scrollHeight - container.scrollTop === container.clientHeight) {
                // Load more conversations
                const nextPage = conversationPage + 1

                setConversationPage(nextPage)
            }
        }
    }

    const handleEmojiClick = (emojiObject) => {
        setMessage((prevMessage) => prevMessage + emojiObject.emoji);
        setShowEmojiPicker(false);
    };

    const showLeftSidebar = isMobile ? location.pathname === '/chat' : true;
    const showRightSidebar = isMobile ? location.pathname !== '/chat' : true;


    return (
        <Box >
            <Paper elevation={0} sx={{ borderRadius: "0px", padding: "10px" }}>
                <Typography
                    variant="h4"
                    fontWeight="bold"
                    textAlign="center"
                    color="primary"
                >
                    {t("CHAT")}
                </Typography>
            </Paper>
            <Container maxWidth='lg' sx={{ padding: '0px', paddingTop: '10px', marginX: 'auto' }} >
            </Container>
            <Box sx={{ height: isMobile ? '90vh' : '80vh', display: 'flex', }}>
                {/* Left sidebar */}

                {
                    showLeftSidebar ? (<Box
                        sx={{
                            minWidth: isMobile ? '100%' : 320,
                            borderRight: 1,
                            borderColor: 'divider',
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: 'background.white',
                        }}
                    >
                        {/* Header with user info and search */}
                        <Paper elevation={0} sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <Avatar src={user.profilePicture} sx={{ mr: 1 }} />
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {isTyping ? `${user.name || 'Unknown'} is typing...` : user.name || 'Unknown'}
                                </Typography>
                            </Box>
                            <TextField
                                fullWidth
                                size="small"
                                placeholder="Search conversations..."
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Paper>

                        {/* Conversations list */}
                        <List
                            sx={{
                                overflow: 'auto',
                                flex: 1,
                                '& .MuiListItem-root:hover': {
                                    backgroundColor: 'primary.main',
                                },
                            }}
                            ref={conversationsContainerRef}
                            onScroll={handleConversationScroll}
                        >
                            {conversations.map((conversation) => (
                                <ListItem
                                    key={conversation._id}
                                    button
                                    onClick={() => {
                                        navigate(`/chat/${conversation._id}`);
                                    }}
                                    sx={{
                                        backgroundColor: selectedChat?._id === conversation._id ? 'action.selected' : 'inherit',
                                        '&:hover': {
                                            backgroundColor: 'action.hover',
                                        },
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar src={conversation.avatar} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={conversation.postId.title}
                                        secondary={
                                            lastMessages[conversation._id]?.senderId ? (lastMessages[conversation._id]?.senderId?._id === user.id
                                                ? `You: ${lastMessages[conversation._id].message}`
                                                : `${lastMessages[conversation._id].senderId.name}: ${lastMessages[conversation._id].message}`) : ""

                                        }
                                        secondaryTypographyProps={{
                                            noWrap: true,
                                            sx: {
                                                color: unreadCounts[conversation._id] ? 'primary.main' : 'text.secondary',
                                                fontWeight: unreadCounts[conversation._id] ? 'bold' : 'normal'
                                            }
                                        }}
                                        primaryTypographyProps={{
                                            noWrap: true,
                                            sx: {
                                                fontWeight: unreadCounts[conversation._id] ? 'bold' : 'normal'
                                            }
                                        }}
                                    />
                                    <Typography variant="caption" color={unreadCounts[conversation._id] ? 'primary' : 'textSecondary'}>
                                        {conversation.timestamp}
                                    </Typography>

                                    <Badge
                                        badgeContent={unreadCounts[conversation._id] || 0}
                                        color='primary'
                                        max={99}
                                        invisible={!unreadCounts[conversation._id]}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Box>) : (<></>)
                }

                {/* Main chat area */}
                {
                    showRightSidebar && (<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        {
                            selectedChat ? (<>  {/* Chat header */}
                                <Paper elevation={0} sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar src="/placeholder.svg?height=40&width=40" sx={{ mr: 1 }} />
                                        <Box>

                                            <Typography variant="subtitle1" fontWeight="bold">
                                                {selectedChat?.postId?.title}
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                {selectedChat?.participants?.find(item => item._id !== user.id).name || 'Unknown user'}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Paper>

                                {/* Messages */}
                                <Box
                                    ref={scrollRef}
                                    sx={{
                                        flex: 1,
                                        overflowY: 'auto',
                                        p: 2,
                                        display: 'flex',
                                        backgroundColor: 'background.form',
                                        flexDirection: 'column-reverse', // Render messages from bottom to top
                                    }}
                                >

                                    {[...messages].map((message, index) => {



                                        return (
                                            <>
                                                {/* {shouldShowDateSeparator(message, messages[index - 1]) && (
                                                    <DateSeparator date={message.createdAt} />
                                                )} */}
                                                <Box
                                                    key={index}
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: message.senderId === user.id ? 'flex-end' : 'flex-start',
                                                        mb: 2,
                                                    }}
                                                >
                                                    <Paper
                                                        elevation={0}
                                                        sx={{
                                                            p: 2,
                                                            maxWidth: '60%',
                                                            backgroundColor: message.senderId === user.id ? 'primary.main' : 'background.white',
                                                            color: message.senderId === user.id ? 'white' : 'text.primary',
                                                            borderRadius: 2,
                                                        }}
                                                    >
                                                        <Typography variant="body1" style={{ wordWrap: 'break-word' }}>{message.content}</Typography>
                                                        <Typography variant="caption" sx={{ opacity: 0.7 }}>
                                                            {new Date(message.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                        </Typography>
                                                    </Paper>
                                                </Box>
                                            </>

                                        )
                                    })}
                                    {loading && (
                                        <div style={{ padding: '8px', textAlign: 'center' }}><CircularProgress /></div> // Loader
                                    )}

                                </Box>

                                {/* Message input */}
                                <Paper
                                    elevation={0}
                                    sx={{
                                        p: 2,
                                        borderTop: 1,
                                        borderColor: 'divider',
                                        display: 'flex',
                                        gap: 1,
                                        position: 'relative',
                                    }}
                                >
                                    <IconButton onClick={() => setShowEmojiPicker((prev) => !prev)}>
                                        <SentimentSatisfiedAltIcon />
                                    </IconButton>
                                    {showEmojiPicker && (
                                        <Box
                                            style={{
                                                position: 'absolute',
                                                bottom: '60px',
                                                left: '10px',
                                                zIndex: 1,
                                            }}
                                        >
                                            <EmojiPicker onEmojiClick={handleEmojiClick} />
                                        </Box>
                                    )}
                                    <TextField
                                        fullWidth
                                        size="small"
                                        placeholder="Type a message..."
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                handleSendMessage()
                                            }
                                        }}
                                    />
                                    <IconButton
                                        color="primary"
                                        onClick={handleSendMessage}
                                        disabled={!message.trim()}
                                    >
                                        <SendIcon />
                                    </IconButton>
                                </Paper> </>) : (<EmptyChatPlaceholder /> // Show placeholder when no chat is selected
                            )
                        }

                    </Box>)
                }

            </Box>

        </Box>
    )
}