import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, Checkbox, Container, FormControl, FormControlLabel, FormGroup, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { CheckBox } from '@mui/icons-material';
import TagIcon from '@mui/icons-material/Tag'; // You can replace this with a hash icon
import CustomTable from '../../components/common/CustomTable';
import CustomTabPanel from '../../components/common/CustomTabPanel';
import TextEditor from '../../components/common/TextEditor';
import Grid from '@mui/material/Grid2';
import QUillEditer from '../../components/common/QuillEditer';




const data = [
    { id: <CheckBox   />, name: 'Live for 14 Day' , validity :'14 days', amount:"3 KWD", action:<Button size='small' variant='outlined'>Edit </Button>},
    { id: <CheckBox   />, name: 'Live for 30 Days Edit',validity :'30 days' ,amount:"3 KWD" , action:<Button size='small' variant='outlined'>Edit </Button>},
  ];
  
  // Columns definition
  const columns = [
    { id: 'id', label: <CheckBox   />, align: 'left' },
    { id: 'name', label: 'Category', align: 'left' },
    { id: 'validity', label: 'Validity', align: 'left' },
    { id: 'amount', label: 'Amount', align: 'left' },
    { id: 'action', label: 'Action', align: 'right' },
  ];


function PaymentPlanItemEdit() {
    const [value, setValue] = React.useState(0);
    const [colorCode, setColorCode] = React.useState('EB6953');
    const [editorContent, setEditorContent] = React.useState('');

    const handleContentChange = (content) => {
      setEditorContent(content);
    };

    const handleColorCodeChange = (event) => {
        setColorCode(event.target.value);
    };

    const handleChange = (newValue) => {
        setValue(newValue);
    };
    return (
        <Box sx={{ width: '100%' }}>
            <Paper elevation={0} sx={{ borderRadius: '0px', padding: '10px' }} >
                <Typography variant='h4' fontWeight='bold' textAlign='center' color='primary'>Add / Edit Payment Plan Item</Typography>
            </Paper>


            <Paper elevation={0} sx={{ borderRadius: '0px', padding: '20px', mt: '20px' }} >
                <Container maxWidth='sm'>
                    <Box style={{ display: 'flex', width: '100%', justifyContent: 'center' }} pb={3}>

                        <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    value="Enable"
                                    control={<Checkbox />}
                                    label="Enable"
                                    labelPlacement="End"
                                />
                                <FormControlLabel
                                    value="Dissable"
                                    control={<Checkbox />}
                                    label="Dissable"
                                    labelPlacement="end"
                                />
                            </FormGroup>
                        </FormControl>
                    </Box>
                </Container>
                <Container maxWidth='sm'>
                    <Box style={{ display: 'flex', width: '100%' }}>
                        <Button variant={value === 0 ? 'contained' : 'text'} style={{ borderRadius: '0px', width: '50%', fontSize: '20px', fontWeight: 'bold' }} onClick={() => handleChange(0)}>English</Button>
                        <Button variant={value === 1 ? 'contained' : 'text'} style={{ borderRadius: '0px', width: '50%', fontSize: '20px', fontWeight: 'bold' }} onClick={() => handleChange(1)}>Arabic</Button>
                    </Box>
                    <CustomTabPanel value={value} index={0} >
                        <Typography variant='body1' fontWeight='bold' >English</Typography>
                        <TextEditor initialValue={editorContent} onChange={handleContentChange} />
                        </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Typography variant='body1' fontWeight='bold'  >Arabic</Typography>
                        <TextEditor initialValue={editorContent} onChange={handleContentChange} />
                        <QUillEditer />
                        </CustomTabPanel>
                </Container>
            </Paper>

            <Paper elevation={0} sx={{ borderRadius: '0px', mt: '20px', padding: '30px' }}>
                <Container maxWidth='sm'>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 5 }}>
                        <Box >
                            <TextField
                                value={colorCode}
                                onChange={handleColorCodeChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <TagIcon /> {/* You can use any hash or similar icon */}
                                        </InputAdornment>
                                    ),
                                }}
                                placeholder="#FFFFFF"
                                fullWidth size='small' />
                        </Box>
                        <Box >
                            <Box sx={{ height: '70px', width: '70px', backgroundColor: `#${colorCode}`, borderRadius: '10px' }} >
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Paper>

            <Paper elevation={0} sx={{ borderRadius: '0px', mt: '20px'}}>
                <CustomTable data={data} columns={columns} />
            </Paper>
            <Paper elevation={0} sx={{ borderRadius: '0px', padding: '20px', mt: '20px' }} >
                <Container maxWidth='sm'>
                    <Box style={{ display: 'flex', width: '100%', justifyContent: 'center' }} pb={3}>
                        <Grid container spacing={2} alignItems='center'>
                            <Grid size={6}>
                                <Typography variant='body1' fontWeight='bold' >Enter Validity (days)</Typography>
                                <TextField fullWidth size='small' />
                            </Grid>
                            <Grid size={6}>
                                <FormControlLabel
                                    value="Enable Discount"
                                    control={<Checkbox />}
                                    label="Enable Discount"
                                    labelPlacement="End"
                                />
                            </Grid>
                            <Grid size={6}>
                                <Typography variant='body1' fontWeight='bold' >Enter price (KWD)</Typography>
                                <TextField fullWidth size='small' />
                            </Grid>
                            <Grid size={6}>
                                <Typography variant='body1' fontWeight='bold' >Discount price (KWD)</Typography>
                                <TextField fullWidth size='small' />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Paper>
            <Paper elevation={0} sx={{ borderRadius: '0px', padding: '20px', mt: '20px' }} >
                <Container maxWidth='sm'>
                    <Box style={{ display: 'flex', width: '100%', justifyContent: 'center' }} pb={3}>

                        <FormControl component="fieldset">
                            <FormGroup aria-label="position" >
                                <FormControlLabel
                                    value="Standard Ads"
                                    control={<Checkbox />}
                                    label="Enable as Standard Ads"
                                    labelPlacement="End"
                                />
                                <FormControlLabel
                                    value="Dissable"
                                    control={<Checkbox />}
                                    label="Enable as Category Featured Ads"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="Dissable"
                                    control={<Checkbox />}
                                    label="Enable as Homepage Featured Ads"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="Dissable"
                                    control={<Checkbox />}
                                    label="Enable as Main Home Page Ads"
                                    labelPlacement="end"
                                />
                            </FormGroup>
                        </FormControl>
                    </Box>
                </Container>
            </Paper>

            <Paper elevation={0} sx={{ borderRadius: '0px', mt: '20px', padding: '30px' }}>
                <Container maxWidth='sm'>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button variant='contained' color='primary' sx={{ mx: '5px', textTransform: 'inherit', minWidth: '150px' }} size='large' >
                            Save
                        </Button>
                    </Box>
                </Container>
            </Paper>




        </Box>

    )
}



export default PaymentPlanItemEdit