import * as React from "react";
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import CustomTable from "../../components/common/CustomTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate, useParams } from "react-router-dom";
import useCategory from "../../hooks/useCategory";
import { useEffect, useState } from "react";
import getLocalizedValue from "../../utils/getLocalizedValue";
import { useFormik } from "formik";
import CustomeMultiSelectDialog from "../../components/common/CustomeMultiSelectDialog";
import useSpecification from "../../hooks/useSpecifications";
import useAdditionalDetails from "../../hooks/useAdditionalDetails";

// Columns definition
const columns = [
  { id: "id", label: <Checkbox />, align: "left" },
  { id: "name", label: "Category", align: "left" },
  { id: "action", label: "Action", align: "right" },
];

function MenuEditPage() {
  const { id } = useParams();
  const {
    categories,
    fetchCategories,
    getCategoryBuId,
    category,
    loading,
    updateCategory,
  } = useCategory();
  const { specifications, getSpecificationsList } = useSpecification();
  const { getAdditionalDetails, additionalDetails } = useAdditionalDetails();
  const [specificationItems, setSpecificationItems] = useState([]);
  const [optionalSpecificationItems, setOptionalSpecificationItems] = useState(
    []
  );
  const [additionalItem, setAdditionalItems] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClickOpen = async (label) => {
    setSelectedLabel(label);
    if (label === "Specifications") {
      await getSpecificationsList();
      setSelectedOptions(formik.values.specification.items);
      setOptions(specifications?.specifications);
    } else if (label === "Optional Specifications") {
      await getSpecificationsList();
      setSelectedOptions(formik.values.optionalSpecifications.items);
      setOptions(specifications?.specifications);
    } else if (label === "Additional Details") {
      await getAdditionalDetails();
      setSelectedOptions(formik.values.additionalDetails.items);
      setOptions(additionalDetails.additionalDetails);
    }
    setOpen(true);
  };

  const handleAddItems = (values, label) => {
    const mapItems = (items) =>
      items?.map((item) => ({
        id: <Checkbox />,
        _id: item._id,
        name: getLocalizedValue(item.name),
        action: (
          <Button
            size="small"
            variant="outlined"
            onClick={() => handleRemoveItem(item, label, items)} // Bind remove function here
          >
            Remove
          </Button>
        ),
      }));

    const setFieldValue = (field, value) => {
      formik.setFieldValue(field, value);
    };

    // Handle each category
    switch (label) {
      case "Specifications":
        setSpecificationItems(mapItems(values));
        setFieldValue("specification.items", values);
        break;

      case "Optional Specifications":
        setOptionalSpecificationItems(mapItems(values));
        setFieldValue("optionalSpecifications.items", values);
        break;

      case "Additional Details":
        setAdditionalItems(mapItems(values));
        setFieldValue("additionalDetails.items", values);
        break;

      default:
        break;
    }

    setOpen(false);
  };

  const handleRemoveItem = (item, label, items) => {
    let updatedItems;
    const mapItems = (items) =>
      items?.map((item) => ({
        id: <Checkbox />,
        _id: item._id,
        name: getLocalizedValue(item.name),
        action: (
          <Button
            size="small"
            variant="outlined"
            onClick={() => handleRemoveItem(item, label, items)}
          >
            Remove
          </Button>
        ),
      }));
    if (label === "Specifications") {
      updatedItems = items.filter((specItem) => specItem._id !== item._id);
      formik.setFieldValue("specification.items", updatedItems);
      setSpecificationItems(mapItems(updatedItems));
    } else if (label === "Optional Specifications") {
      updatedItems = items.filter((specItem) => specItem._id !== item._id);
      setOptionalSpecificationItems(mapItems(updatedItems));
      formik.setFieldValue("optionalSpecifications.items", updatedItems);
    } else if (label === "Additional Details") {
      updatedItems = items.filter((specItem) => specItem._id !== item._id);
      setAdditionalItems(mapItems(updatedItems));
      formik.setFieldValue("additionalDetails.items", updatedItems);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: {
        en: "",
        ar: "",
      },
      additionalDetails: { isEnabled: false, items: [] },
      optionalSpecifications: { isEnabled: false, items: [] },
      specification: { isEnabled: false, items: [] },
    },
    onSubmit: async (values) => {
      try {
        const transformItems = (items) =>
          items.map((item, index) => ({
            id: item._id,
            displayOrder: index + 1,
          }));

        const payload = {
          additionalDetails: {
            ...values.additionalDetails,
            items: transformItems(values.additionalDetails.items),
          },
          optionalSpecifications: {
            ...values.optionalSpecifications,
            items: transformItems(values.optionalSpecifications.items),
          },
          specification: {
            ...values.specification,
            items: transformItems(values.specification.items),
          },
        };

        if (id) {
          await updateCategory(id, payload);
        }
        navigate("/menu");
      } catch (error) {
        console.error("Error during form submission:", error);
        alert("Oops.. Something went wrong.");
      }
    },
  });

  useEffect(() => {
    fetchCategories({ limit: 100 });
  }, []);

  useEffect(() => {
    if (id) {
      getCategoryBuId(id);
    }
  }, [id]);

  useEffect(() => {
    if (category) {
      // Helper function to map items with Remove button
      const mapItems = (items, label) =>
        items?.map((item) => ({
          id: <Checkbox />,
          _id: item._id,
          name: getLocalizedValue(item.name),
          action: (
            <Button
              size="small"
              variant="outlined"
              onClick={() => handleRemoveItem(item, label, items)}
            >
              Remove
            </Button>
          ),
        }));

      // Setting form values
      formik.setValues({
        additionalDetails: {
          isEnabled: category.additionalDetails?.isEnabled || false,
          items: category.additionalDetails?.items || [],
        },
        optionalSpecifications: {
          isEnabled: category.optionalSpecifications?.isEnabled || false,
          items: category.optionalSpecifications?.items || [],
        },
        specification: {
          isEnabled: category.specification?.isEnabled || false,
          items: category.specification?.items || [],
        },
      });

      // Setting items for the different categories with "Remove" button
      setSpecificationItems(
        mapItems(category?.specification?.items || [], "Specifications")
      );
      setOptionalSpecificationItems(
        mapItems(
          category?.optionalSpecifications?.items || [],
          "Optional Specifications"
        )
      );
      setAdditionalItems(
        mapItems(category?.additionalDetails?.items || [], "Additional Details")
      );
    }
  }, [category]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper elevation={0} sx={{ borderRadius: "0px", padding: "10px" }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          color="primary"
        >
          Add / Edit Menu Item
        </Typography>
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", padding: "20px", mt: "20px" }}
      >
        <Container maxWidth="sm">
          <Typography
            variant="body1"
            fontWeight="bold"
            color="primary"
            textAlign="center"
          >
            Parent Category Selection
          </Typography>
          <Box style={{ width: "100%", margin: "20px 0px" }}>
            <InputLabel id="demo-simple-select-label">
              Select Menu Item
            </InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={id}
              label="Input Props"
              name="primary_category"
              onChange={(e) => navigate(`/menu/${e.target.value}`)}
            >
              {categories?.categories?.map((item, index) => (
                <MenuItem key={index} value={item._id}>
                  {item.name.en}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              margin: "20px 0px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px" }}
              size="large"
              disabled={!id}
              onClick={() => navigate("/menu/sub", { state: { id } })}
            >
              Edit Sub Category Items
            </Button>
          </Box>
        </Container>
      </Paper>

      <Paper elevation={0} sx={{ borderRadius: "0px", mt: "20px" }}>
        <Accordion defaultExpanded elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" fontSize="large" />}
          >
            <Box style={{ textAlign: "center", width: "100%" }}>
              <Typography
                textAlign="center"
                variant="h4"
                fontWeight="bold"
                color="primary"
              >
                Specification Items
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Container maxWidth="sm">
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...formik.getFieldProps("specification.isEnabled")}
                          checked={formik.values.specification.isEnabled}
                        />
                      }
                      label="Enable"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!formik.values.specification.isEnabled}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "specification.isEnabled",
                              !e.target.checked
                            )
                          }
                        />
                      }
                      label="Disable"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    mx: "5px",
                    textTransform: "inherit",
                    minWidth: "150px",
                  }}
                  size="large"
                  onClick={() => handleClickOpen("Specifications")}
                >
                  Add Required Specification Item
                </Button>
              </Box>
            </Container>
            <CustomTable
              data={specificationItems}
              columns={columns}
              hidePagination
            />
          </AccordionDetails>
        </Accordion>
      </Paper>

      <Paper elevation={0} sx={{ borderRadius: "0px", mt: "20px" }}>
        <Accordion defaultExpanded elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" fontSize="large" />}
          >
            <Box style={{ textAlign: "center", width: "100%" }}>
              <Typography
                textAlign="center"
                variant="h4"
                fontWeight="bold"
                color="primary"
              >
                Optional Specification Items
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Container maxWidth="sm">
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...formik.getFieldProps(
                            "optionalSpecifications.isEnabled"
                          )}
                          checked={
                            formik.values.optionalSpecifications.isEnabled
                          }
                        />
                      }
                      label="Enable"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            !formik.values.optionalSpecifications.isEnabled
                          }
                          onChange={(e) =>
                            formik.setFieldValue(
                              "optionalSpecifications.isEnabled",
                              !e.target.checked
                            )
                          }
                        />
                      }
                      label="Disable"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    mx: "5px",
                    textTransform: "inherit",
                    minWidth: "150px",
                  }}
                  size="large"
                  onClick={() => handleClickOpen("Optional Specifications")}
                >
                  Add Optional Specification Item
                </Button>
              </Box>
            </Container>
            <CustomTable
              data={optionalSpecificationItems}
              columns={columns}
              hidePagination
            />
          </AccordionDetails>
        </Accordion>
      </Paper>

      <Paper elevation={0} sx={{ borderRadius: "0px", mt: "20px" }}>
        <Accordion defaultExpanded elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" fontSize="large" />}
          >
            <Box style={{ textAlign: "center", width: "100%" }}>
              <Typography
                textAlign="center"
                variant="h4"
                fontWeight="bold"
                color="primary"
              >
                Additional Details
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Container maxWidth="sm">
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...formik.getFieldProps(
                            "additionalDetails.isEnabled"
                          )}
                          checked={formik.values.additionalDetails.isEnabled}
                        />
                      }
                      label="Enable"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!formik.values.additionalDetails.isEnabled}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "additionalDetails.isEnabled",
                              !e.target.checked
                            )
                          }
                        />
                      }
                      label="Disable"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    mx: "5px",
                    textTransform: "inherit",
                    minWidth: "150px",
                  }}
                  size="large"
                  onClick={() => handleClickOpen("Additional Details")}
                >
                  Add Additional Details Item
                </Button>
              </Box>
            </Container>
            <CustomTable
              data={additionalItem}
              columns={columns}
              hidePagination
            />
          </AccordionDetails>
        </Accordion>
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "20px" }}
      >
        <Box display="flex" justifyContent="center">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ textTransform: "inherit", minWidth: "150px" }}
            onClick={formik.handleSubmit}
            disabled={!id}
          >
            Save
          </Button>
        </Box>
      </Paper>

      <CustomeMultiSelectDialog
        open={open}
        setOpen={setOpen}
        options={options}
        selectedOptions={selectedOptions}
        handleAddItems={handleAddItems}
        title={selectedLabel}
      />
    </Box>
  );
}

export default MenuEditPage;
