import { createSlice } from "@reduxjs/toolkit";

// Create a slice for categories
const menuSlice = createSlice({
  name: "categoryMenu",
  initialState: {
    menuItems: [],
    menu: {},
    loading: false,
    error: null,
  },
  reducers: {
    setMenuItems: (state, action) => {
      state.menuItems = action.payload.data || [];
      state.loading = false;
    },
    setMenu: (state, action) => {
      state.menu = action.payload.data || [];
      state.loading = false;
    },
  },
});

// Export the loading and error selectors if needed
export const selectMenuLoading = (state) => state.categoryMenu.loading;
export const selectMenuError = (state) => state.categoryMenu.error;
export const selectMenuItems = (state) => state.categoryMenu.menuItems;
export const selectMenu = (state) => state.categoryMenu.menu;

// Export the reducer to include in the store
export const { setMenu, setMenuItems } = menuSlice.actions;
export default menuSlice.reducer;
