import {
  Box,
  Button,
  Checkbox,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import CustomTable from "../../components/common/CustomTable";
import { useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import getLocalizedValue from "../../utils/getLocalizedValue";
import { formatTimestamp } from "../../utils/formatHelper";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// Columns definition
const columns = [
  { id: "_id", label: <Checkbox />, align: "left" },
  { id: "name", label: "Name", align: "left" },
  { id: "joinedDate", label: "Joined Date", align: "left" },
  { id: "status", label: "Status", align: "left" },
  { id: "action", label: "Action", align: "right" },
];

function UsersList() {
  const navigate = useNavigate();
  const { getUserList, users, deleteUsers, loading } = useUser();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    page: users?.page - 1 || 0,
    limit: users?.limit || 10,
  });
  const [pageData, setPageData] = useState({
    totalCount: users?.totalCount || 0,
    totalPages: users?.totalPages || 0,
  });
  const [selectedIds, setSelectedIds] = useState([]);
  const [filter, setFilter] = useState({
    startDate: "",
    endDate: "",
  });

  const handleCheckboxChange = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((item) => item !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleSearch = () => {
    setPagination({ ...pagination, page: 0 });
    const payload = {
      page: 1,
      limit: pagination?.limit,
      search: search,
    };
    getUserList(payload);
  };

  const handleDelete = () => {
    if (selectedIds.length === 0) {
      alert("Please select at least one user to delete.");
      return;
    }
    const message =
      selectedIds.length > 1
        ? "Are you sure you want to delete these users?"
        : "Are you sure you want to delete this user?";
    if (window.confirm(message)) {
      deleteUsers({ ids: [...selectedIds] })
        .then((res) => {
          alert("Reports deleted successfully!");
          setSelectedIds([]);
          navigate("/users");
        })
        .catch((err) => {
          alert(
            `Error deleting users: ${err.message || "Something went wrong"}`
          );
        });
    }
  };
  useEffect(() => {
    const payload = {
      page: pagination?.page + 1,
      limit: pagination?.limit,
      search: "",
      filter: (filter.startDate || filter?.endDate) && JSON.stringify(filter),
    };
    getUserList(payload);
  }, [pagination?.page, pagination?.limit, filter]);
  useEffect(() => {
    if (users?.users?.length > 0) {
      const items = users?.users;
      setData(
        items?.map((item) => ({
          _id: (
            <Checkbox
              checked={selectedIds.includes(item._id)}
              onChange={() => handleCheckboxChange(item._id)}
            />
          ),
          name: item.name,
          joinedDate: formatTimestamp(item.createdAt, false),
          status: item.status,
          totalAds: item.totalAds,
          action: (
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                navigate(`/user/${item?._id}`);
              }}
            >
              view
            </Button>
          ),
        }))
      );
    } else {
      setData([]);
    }
    setPageData({
      totalCount: users?.totalCount || 0,
      totalPages: users?.totalPages || 0,
    });
  }, [users, handleCheckboxChange]);
  return (
    <div>
      <Paper elevation={0} sx={{ borderRadius: "0px", padding: "10px" }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          color="primary"
        >
          Users List
        </Typography>
      </Paper>
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "20px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px" }}
              size="large"
              onClick={() => navigate("/users/create")}
            >
              Create User
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px" }}
              size="large"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "20px",
            }}
          >
            <TextField
              fullWidth
              sx={{ mx: "5px" }}
              size="small"
              value={search}
              onChange={(e) => {
                e.preventDefault();
                setSearch(e.target.value);
              }}
            />

            <Button
              variant="contained"
              color="primary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px" }}
              size="medium"
              onClick={handleSearch}
            >
              Search
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "20px",
            }}
          ></Box>
        </Container>
        <Container maxWidth="md">
          <DateFilter setFilter={setFilter} />
        </Container>
      </Paper>

      <Paper elevation={0} sx={{ borderRadius: "0px", mt: "20px" }}>
        <CustomTable
          data={data}
          columns={columns}
          loading={loading}
          pagination={{ ...pagination, ...pageData }}
          setPagination={setPagination}
        />
      </Paper>
    </div>
  );
}

function DateFilter({ setFilter }) {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleFilter = () => {
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);

    if (fromDate && toDate) {
      if (endDate < startDate) {
        alert("End date must be greater than start date.");
        return;
      }
    }

    const payload = {
      startDate: fromDate ? format(new Date(startDate), "yyyy-MM-dd") : null,
      endDate: toDate ? format(new Date(endDate), "yyyy-MM-dd") : null,
    };

    setFilter(payload);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems="end"
        justifyContent="center"
        gap={2}
        p={2}
        sx={{
          margin: "auto",
        }}
      >
        <Box>
          <Typography variant="body2">Date From</Typography>
          <DatePicker
            value={fromDate}
            onChange={(newValue) => setFromDate(newValue)}
            renderInput={(params) => (
              <TextField size="small" {...params} fullWidth />
            )}
          />
        </Box>
        <Box>
          <Typography variant="body2">Date To</Typography>
          <DatePicker
            value={toDate}
            onChange={(newValue) => setToDate(newValue)}
            renderInput={(params) => (
              <TextField size="small" {...params} fullWidth />
            )}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFilter}
          fullWidth
          sx={{ maxWidth: { sm: 120 } }}
        >
          Filter
        </Button>
      </Box>
    </LocalizationProvider>
  );
}

export default UsersList;
