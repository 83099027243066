import React, { useState } from 'react';
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  InputBase,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  TablePagination,
  useTheme,
  useMediaQuery,
  Menu,
  MenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ListIcon from '@mui/icons-material/List';
import InventoryIcon from '@mui/icons-material/Inventory';
import BuildIcon from '@mui/icons-material/Build';
import DescriptionIcon from '@mui/icons-material/Description';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PeopleIcon from '@mui/icons-material/People';
import ChatIcon from '@mui/icons-material/Chat';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PaymentIcon from '@mui/icons-material/Payment';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
const drawerWidth = 300;



const navigation = [
  { name: 'Dashboard', icon: DashboardIcon,path:'/' },
  { name: 'Categories', icon: ListIcon,path:'/category' },
  // { name: 'Item List', icon: InventoryIcon,path:'/' },
  { name: 'Specifications', icon: BuildIcon,path:'/specification' },
  { name: 'Additional Details', icon: DescriptionIcon,path:'/additional-details' },
  { name: 'Menu', icon: ListIcon,path:'/menu' },
  { name: 'Ad Management', icon: LocalOfferIcon,path:'/ads' },
  { name: 'Users', icon: PeopleIcon ,path:'/users'},
  { name: 'Notifications', icon: NotificationsIcon,path:'/' },
  { name: 'Chats', icon: ChatIcon,path:'/chat' },
  { name: 'Reports', icon: AssessmentIcon,path:'/reports' },
  { name: 'Payment Plans', icon: PaymentIcon,path:'/payment-plan' },
  { name: 'Settings', icon: SettingsIcon,path:'/' },
];

const categories = [
  'Cars', 'Classic Cars', 'Junk Cars', 'Wanted Cars', 'Bikes',
  'Watercraft', 'CMVs', 'Spare Parts', 'Accessories', 'Services',
  'Rentals', 'Dealerships', 'Toyota', 'Lexus', 'Chevrolet',
];

export default function MuiDashboardLayout() {
  const navigate = useNavigate()
  const { isAuthenticated, logout } = useAuth();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const drawer = (
    <div>
      <Toolbar>
        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center' ,  width:'100%'}}>
          <img src='./logo-white.png' width={100}  alt='Sold logo' />
        </Box>
      </Toolbar>
      <List>
        {navigation.map((item) => (
          <ListItem key={item.name} disablePadding    component={Link} to={item.path}>
            <ListItemButton>
              <ListItemIcon sx={{ color: 'white' }}>
                <item.icon />
              </ListItemIcon>
              <ListItemText primary={item.name} sx={{ color: 'white' }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { lg: `calc(100% - ${drawerWidth}px)` },
            ml: { lg: `${drawerWidth}px` },
            bgcolor: 'background.form',
            color: 'text.primary',
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { lg: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ flexGrow: 1 }}>
              <Box
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400,backgroundColor:'background.white' }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search..."
                  inputProps={{ 'aria-label': 'search' }}
                />
                <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Box>
            </Box>
            <IconButton color="inherit" onClick={handleMenuOpen}>
              <NotificationsIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleMenuClose}>Notification 1</MenuItem>
              <MenuItem onClick={handleMenuClose}>Notification 2</MenuItem>
            </Menu>
            <Button color="inherit" onClick={handleLogout} startIcon={<ExitToAppIcon />}>
              Logout
            </Button>
            <Button color="inherit">عربي</Button>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
        >
          <Drawer
            variant={isMobile ? "temporary" : "permanent"}
            open={isMobile ? mobileOpen : true}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
                bgcolor: 'primary.main',
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { lg: `calc(100% - ${drawerWidth}px)` },
            backgroundColor: "background.main" 
          }}
        >
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
  );
}