import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Checkbox,
  ListItemText,
  Badge,
  FormControl,
  Box,
  Grid,
  Typography,
  Menu,
  Button,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { styled } from "@mui/system";

// Custom styles for the component
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontWeight: "bold",
  },
}));

const CustomSelect = ({
  name,
  icon: Icon,
  options = [],
  isRequired = true,
  isMultiSelect = false, // Add isMultiSelect prop with default value false
  onSelectChange,
  value,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(
    value ? value : isMultiSelect ? [] : ""
  ); // Use empty array for multiple, or empty string for single

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    if (isMultiSelect) {
      // Multi-select logic
      const currentIndex = selectedOptions.findIndex(
        (selected) => selected.value === option.value
      );
      const newSelectedOptions = [...selectedOptions];

      if (currentIndex === -1) {
        // If the option is not selected, add it to the selected options
        newSelectedOptions.push(option);
      } else {
        // If the option is already selected, remove it from the selected options
        newSelectedOptions.splice(currentIndex, 1);
      }

      setSelectedOptions(newSelectedOptions);
      onSelectChange(newSelectedOptions);
    } else {
      setSelectedOptions(option);
      onSelectChange(option);
      handleClose();
    }
  };

  useEffect(() => {
    setSelectedOptions(value ? value : isMultiSelect ? [] : "");
  }, [value]);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        cursor: "pointer",
        p: 1,
        borderRadius: "10px",
        my: 1,
      }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            {Icon && <Icon color="primary" />}
            <Typography variant="body1" fontWeight="bold">
              {name}
              {isRequired && (
                <Typography component="span" color="red">
                  *
                </Typography>
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <FormControl>
            <Button
              id="basic-button"
              aria-controls={Boolean(anchorEl) ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={Boolean(anchorEl) ? "true" : undefined}
              onClick={handleClick}
            >
              <StyledBadge
                badgeContent={
                  isMultiSelect
                    ? selectedOptions.length
                    : selectedOptions.value
                    ? 1
                    : 0
                }
                color="primary"
              >
                <Typography variant="body2" color="primary">
                  {/* {!isMultiSelect ?  selectedOptions || 'Select Option' : ""} */}
                </Typography>
                <ArrowForwardIosIcon
                  color="primary"
                  fontSize="medium"
                  style={{ fontWeight: "bold" }}
                />
              </StyledBadge>
            </Button>

            {/* Custom Menu Dropdown */}
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {options.map((option, index) => (
                <MenuItem
                  key={index}
                  selected={value.value === option.value} // Highlight the selected option in single select
                  onClick={() => handleOptionClick(option)}
                >
                  {isMultiSelect && (
                    <Checkbox
                      checked={
                        selectedOptions.findIndex(
                          (selected) => selected.value === option.value
                        ) > -1
                      }
                    />
                  )}
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Menu>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomSelect;
