import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Divider,
  IconButton,
  Collapse,
  Paper,
  Container,
} from "@mui/material";
import {
  Add,
  SubdirectoryArrowRight,
  Delete,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import ToyotaIcon from "@mui/icons-material/DirectionsCar"; // Replace with your icons
import { useLocation, useNavigate } from "react-router-dom";
import { menuService } from "../../services/menu.api";
import CustomeSelectDialog from "../../components/common/CustomeSelectDialog";
import useMenu from "../../hooks/useMenu";
import useCategory from "../../hooks/useCategory";
import getLocalizedValue from "../../utils/getLocalizedValue";

const EditSubCategory = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { getCategoryBuId, category, loading, fetchCategories, categories } =
    useCategory();
  const { getMenu, menu, updateMenu } = useMenu();
  const [categorieSet, setCategories] = useState([]); // Initial load with one row
  const [openSubCategories, setOpenSubCategories] = useState({ 1: true }); // Track open/close state for collapse
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(""); // 'add' or 'subItem'
  const [currentCategoryId, setCurrentCategoryId] = useState(null);
  const [options, setOptions] = useState([]);
  // Toggle collapse
  const handleCollapseClick = async (id) => {
    const data = await menuService.fetchMenu(id);
    if (data?.success) {
      setCategories((prevCategories) =>
        prevCategories.map((category) =>
          category._id === id
            ? {
                ...category,
                subCategories: [
                  ...(category.subCategories || []),
                  ...data.data
                    .filter(
                      (newSub) =>
                        !(category.subCategories || []).some(
                          (existing) => existing._id === newSub._id
                        )
                    )
                    .map((newSub) => ({ ...newSub, isFromAPI: true })),
                ],
              }
            : category
        )
      );
    }

    setOpenSubCategories((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  // Open modal
  const handleAddOrSubItem = (type, categoryId) => {
    setModalType(type);
    setCurrentCategoryId(categoryId);
    setModalOpen(true);
  };

  // Close modal
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleAddCategory = (newCategoryItem, label) => {
    // Add category under the parent category or recursively to subcategories
    const addCategoryToParent = (categories, parentId, newCategory) =>
      categories.map((category) =>
        category._id === parentId
          ? {
              ...category,
              subCategories: [
                ...(category.subCategories || []),
                { ...newCategory, parentId: category._id, subCategories: [] },
              ],
            }
          : {
              ...category,
              subCategories: category.subCategories
                ? addCategoryToParent(
                    category.subCategories,
                    parentId,
                    newCategory
                  )
                : [],
            }
      );

    // Find the parent category of the given child
    const findParentCategoryId = (categories, childId) => {
      for (const category of categories) {
        if (category.subCategories) {
          const found = category.subCategories.find(
            (sub) => sub._id === childId
          );
          if (found) return category._id;
          const parentId = findParentCategoryId(
            category.subCategories,
            childId
          );
          if (parentId) return parentId;
        }
      }
      return null;
    };

    let updatedCategories;

    if (modalType === "subItem") {
      updatedCategories = addCategoryToParent(
        categorieSet,
        currentCategoryId,
        newCategoryItem
      );
    } else if (modalType === "add") {
      const parentCategoryId = findParentCategoryId(
        categorieSet,
        currentCategoryId
      );
      updatedCategories = parentCategoryId
        ? addCategoryToParent(categorieSet, parentCategoryId, newCategoryItem)
        : [
            ...categorieSet,
            {
              _id: newCategoryItem._id,
              name: newCategoryItem.name,
              parentId: newCategoryItem.parentId,
              subCategories: [],
            },
          ];
    }

    setCategories(updatedCategories);
    setModalOpen(false);
  };

  // Delete category or subcategory
  const handleDeleteCategory = (categoryId, subCategoryId = null) => {
    const deleteCategoryFromParent = (categories, targetId) =>
      categories.reduce((acc, category) => {
        if (category._id === targetId) {
          if (category.isFromAPI) {
            // Instead of deleting, set parentId to an empty string
            acc.push({ ...category, parentId: "" });
          }
          return acc; // Skip adding this category to effectively delete it
        }

        const updatedCategory = {
          ...category,
          subCategories: category.subCategories
            ? deleteCategoryFromParent(category.subCategories, targetId)
            : [],
        };

        acc.push(updatedCategory);
        return acc;
      }, []);

    const updatedCategories = subCategoryId
      ? deleteCategoryFromParent(categorieSet, subCategoryId)
      : deleteCategoryFromParent(categorieSet, categoryId);

    setCategories(updatedCategories);
  };

  const handleSave = () => {
    const flattenCategories = (categories) => {
      return categories.reduce((result, category) => {
        result.push({ _id: category._id, parentId: category?.parentId });

        if (category.subCategories && category.subCategories.length > 0) {
          result = result.concat(flattenCategories(category.subCategories));
        }

        return result;
      }, []);
    };
    const flattenedCategories = flattenCategories(categorieSet);
    const categoryMap = new Map();

    flattenedCategories.forEach((item) => {
      if (!categoryMap.has(item._id)) {
        categoryMap.set(item._id, { ...item });
      } else {
        const existingItem = categoryMap.get(item._id);
        if (existingItem.parentId === "" && item.parentId !== "") {
          existingItem.parentId = item.parentId;
        }
      }
    });

    const result = Array.from(categoryMap.values());
    updateMenu({ menu: result });

    navigate("/menu/sub", { state: { id: state.id } });
  };

  useEffect(() => {
    if (state?.id) {
      fetchCategories({ limit: 100, page: 1 });
      getMenu(state.id);
    }
  }, [state]);
  useEffect(() => {
    if (menu.length > 0) {
      const updatedMenu = menu.map((item) => ({
        ...item,
        isFromAPI: true,
      }));
      setCategories(updatedMenu);
    } else if(menu) {
      setModalType("add");
      setModalOpen(true);
    }
  }, [menu]);

  useEffect(() => {
    if (categories?.categories?.length > 0) {
      setOptions(categories.categories);
    }
  }, [categories]);
  // Recursive function to render categorieSet and subcategorieSet
  const renderCategory = (category, level = 0, parentId = null) => {
    if (category.parentId === "") return null;
    const isOpen = openSubCategories[category._id] || false;

    return (
      <Box key={category.id} sx={{ ml: level * 5, mt: 1 }}>
        <ListItem
          sx={{
            backgroundColor: "#d4d4d4",
            py: 1,
            pl: 2,
            fontSize: "0.875rem",
          }}
        >
          <ListItemIcon>
            {category.icon || <ToyotaIcon color="error" />}
          </ListItemIcon>
          <ListItemText primary={getLocalizedValue(category.name)} />
          <Button
            color="black"
            startIcon={<Add color="primary" fontSize="20" />}
            onClick={() => handleAddOrSubItem("add", category._id)}
            style={{ textTransform: "initial", fontWeight: "bold" }}
          >
            Add
          </Button>
          <Button
            color="black"
            startIcon={<SubdirectoryArrowRight color="primary" fontSize="20" />}
            onClick={() => handleAddOrSubItem("subItem", category._id)}
            style={{ textTransform: "initial", fontWeight: "bold" }}
          >
            Sub Item
          </Button>

          <Button
            color="black"
            startIcon={<Delete color="primary" fontSize="20" />}
            onClick={() => handleDeleteCategory(parentId, category._id)}
            style={{ textTransform: "initial", fontWeight: "bold" }}
          >
            Delete
          </Button>
          <IconButton onClick={() => handleCollapseClick(category._id)}>
            {isOpen ? (
              <ExpandLess color="primary" />
            ) : (
              <ExpandMore color="primary" />
            )}
          </IconButton>
        </ListItem>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {category.subCategories?.map((subCategory) =>
              renderCategory(subCategory, level + 1, category._id)
            )}
          </List>
        </Collapse>
      </Box>
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper elevation={0} sx={{ borderRadius: "0px", padding: "10px" }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          color="primary"
        >
          Add / Edit Sub Category Items
        </Typography>
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", minHeight: "60vh" }}
      >
        <Box sx={{ p: 3 }}>
          <Typography
            variant="h6"
            fontWeight="bold"
            align="center"
            color="primary"
            gutterBottom
          >
            Sub Category Selection
          </Typography>
          <Divider />
          <List>
            {categorieSet.map((category) =>
              renderCategory(category, 0, category?._id)
            )}
          </List>

          {/* Modal for Adding/Sub Item */}
          <CustomeSelectDialog
            open={isModalOpen}
            setOpen={setModalOpen}
            options={options}
            handleAddItems={handleAddCategory}
            title={"Category"}
          />
        </Box>
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px" }}
              size="large"
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Container>
      </Paper>
    </Box>
  );
};

export default EditSubCategory;
