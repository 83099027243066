import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TextEditor = ({ initialValue, onChange }) => {
  const [content, setContent] = useState(initialValue || '');

  const handleEditorChange = (content, editor) => {
    setContent(content);
    if (onChange) {
      onChange(content);  // Call parent callback if exists
    }
  };

  return (
    <Editor
      value={content}
      onEditorChange={handleEditorChange}
      init={{
        height: 300,
        menubar: 'file edit view insert format tools table',
        toolbar: [
          'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | ' +
          'outdent indent | fontselect fontsizeselect | formatselect'
        ],
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        font_formats:
          'Arial=arial,helvetica,sans-serif;' +
          'Courier New=courier new,courier;' +
          'Georgia=georgia,palatino;' +
          'Tahoma=tahoma,arial,helvetica,sans-serif;' +
          'Verdana=verdana,geneva;',
      }}
    />
  );
};

export default TextEditor;
