import { Box, Button, Container, Paper, TextField, Typography } from '@mui/material'
import React from 'react'
import CustomTable from '../../components/common/CustomTable'
import { CheckBox } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const data = [
    { id: <CheckBox   />, name: 'Standard' ,action:<Button size='small' variant='outlined'  color='secondary' >Edit </Button>},
    { id: <CheckBox   />, name: 'Featured', action:<Button size='small' variant='outlined'  color='secondary'>Edit </Button>},
    { id: <CheckBox   />, name: 'Plus', action:<Button size='small' variant='outlined'  color='secondary'>Edit </Button>},
    { id: <CheckBox   />, name: 'VIP', action:<Button size='small' variant='outlined'  color='secondary'>Edit </Button>},
    ];
  
  // Columns definition
  const columns = [
    { id: 'id', label: <CheckBox   />, align: 'left' },
    { id: 'name', label: 'Payment Plans', align: 'left' },
    { id: 'action', label: 'Action', align: 'right' },
  ];

function PaymentPlansPage() {
    const navigate = useNavigate()
    return (
        <div>
            <Paper elevation={0} sx={{ borderRadius: '0px', padding: '10px' }} >
                <Typography variant='h4' fontWeight='bold' textAlign='center' color='primary'>Payment Plans</Typography>
            </Paper>

            <Paper elevation={0} sx={{ borderRadius: '0px', mt: '20px', padding: '20px' }}>
                <Container maxWidth='sm'>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button 
                            variant='contained' 
                            color='primary' 
                            sx={{ mx: '5px', textTransform: 'inherit' , minWidth:'150px' }}
                            size='large'
                            onClick ={()=>navigate('/payment-plan/create')}
                            >
                            Add Item
                        </Button>
                        <Button variant='contained' color='secondary' sx={{ mx: '5px', textTransform: 'inherit', minWidth:'150px' }} size='large'>
                            Delete
                        </Button>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '20px' }}>

                        <TextField fullWidth  sx={{ mx: '5px'}} size='small' />

                        <Button variant='contained' color='primary' sx={{ mx: '5px', textTransform: 'inherit' , minWidth:'150px' }} size='medium' >
                            Search
                        </Button>

                    </Box>
                </Container>
            </Paper>

            <Paper elevation={0} sx={{ borderRadius: '0px', mt: '20px'}}>
                <CustomTable data={data} columns={columns} />
            </Paper>
        </div>
    )
}


export default PaymentPlansPage