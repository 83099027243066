import { createSlice } from "@reduxjs/toolkit";

// Create a slice for categories
const adSlice = createSlice({
  name: "ad",
  initialState: {
    adItems: [],
    ad: {},
    loading: false,
    error: null,
  },
  reducers: {
    setAdItems: (state, action) => {
      state.adItems = action.payload.data || [];
      state.loading = false;
    },
    setAd: (state, action) => {
      state.ad = action.payload.data || [];
      state.loading = false;
    },
  },
});

// Export the loading and error selectors if needed
export const selectAdLoading = (state) => state.ad.loading;
export const selectAdError = (state) => state.ad.error;
export const selectAdItems = (state) => state.ad.adItems;
export const selectAd = (state) => state.ad.ad;

// Export the reducer to include in the store
export const { setAd, setAdItems } = adSlice.actions;
export default adSlice.reducer;
