import React, { useEffect, useMemo, useState } from "react";
import { Button, Typography, Box, FormHelperText } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomSelect from "../common/CustomSelect";
import AppsIcon from "@mui/icons-material/Apps";
import MapComponent from "./MapComponent";
import NestedCategorySelect from "./NestedCategorySelect";
import CustomInput from "../common/CustomInput";
import useConfig from "../../hooks/useConfig";
import useCreatePost from "../../hooks/useCreatePost";
import getLocalizedValue from "../../utils/getLocalizedValue";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object({
  selectedCategory: Yup.object({
    categoryId: Yup.string().required("Category is required"), // Only categoryId is required
    name: Yup.object({
      en: Yup.string(),
      ar: Yup.string(),
    }),
  }).required(),

  selectedCountry: Yup.string().required("Country is required"),
  title: Yup.string().trim().required("Post title is required"),
  price: Yup.number()
    .min(1, "Price must be at least 1")
    .required("Price is required"),
  description: Yup.string().trim().required("Description is required"),
  location: Yup.object({
    locationName: Yup.string().trim().required("Location name is required"), // Only locationName is required
    address: Yup.object(),
    markerPosition: Yup.object({
      lat: Yup.string(),
      lng: Yup.string(),
    }),
  }).required(),
});

function findItemByText(text, items) {
  const searchText = text.trim();
    if (!searchText) {
    return {
      label: '',
      value: '',
      currency: ''
    };
  }
  const filteredItem = items.find(item => {
    const nameEn = item.name?.en?.toLowerCase() || '';  // English name (lowercased)
    const nameAr = item.name?.ar || '';                 // Arabic name (not lowercased)
    return nameEn === searchText.toLowerCase() || nameAr === searchText;
  });

  if (!filteredItem) {
    return {
      label: '',
      value: '',
      currency: ''
    };
  }
  return {
    label: filteredItem.name.en,         // Use localized name
    value: filteredItem._id,                             // Return the _id
    currency: filteredItem.currency.en   // Use localized currency
  };
}


const AdInfo = (props) => {
  const { t } = useTranslation();
  const { handleNext } = props;
  const { fetchCountry, countries, loading } = useConfig();
  const { saveData, stepData } = useCreatePost();


  useEffect(() => {
    fetchCountry();
  }, []);

  // Formik setup
  const formik = useFormik({
    initialValues: stepData.adInfo || {
      breadcrumbs: [],
      selectedCategory: {
        categoryId: "",
        name: { en: "", ar: "" },
      },
      selectedCountry: "",
      currency: "",
      title: "",
      price: "",
      description: "",
      location: {
        locationName: "",
        address: {},
        markerPosition: {},
      },
    },
    validationSchema,
    onSubmit: (values) => {
      saveData("adInfo", { ...values });
      handleNext();
    },
  });

  // Update currency when the country changes
  const handleCountryChange = (value) => {
    formik.setFieldValue("selectedCountry", value.label);
    if (value.currency) {
      formik.setFieldValue("currency", value.currency);
    }
  };

  const handleLocationChange = (value) => {
    formik.setFieldValue("location", value);
  };

  const handleSelectCategory = (value) => {
    if (value) {
      formik.setFieldValue("selectedCategory", {
        categoryId: value._id,
        name: value.name,
      });
    }
  };

  const handleSelectBreadcrumbs = (value) => {
    if (value) {
      formik.setFieldValue("breadcrumbs", value);
    }
  };


  const result = useMemo(() => findItemByText(formik.values.selectedCountry, countries.map((item) => ({
    name:item.name,
    _id: item._id,
    currency: item.currency,
  }))), [formik.values.selectedCountry, countries]);


  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        maxWidth: "600px",
        margin: "auto",
        padding: "16px 20px",
        borderRadius: "8px",
        backgroundColor: "background.form",
      }}
    >
      {/* Category Selection */}
      <Typography variant="body1" fontWeight="bold">
        {t("SELECT_CATEGORY")}
      </Typography>
      <NestedCategorySelect
        initialBreadcrumbs={formik.values.breadcrumbs}
        handleSelectBreadcrumbs={handleSelectBreadcrumbs}
        handleSelectCategory={handleSelectCategory}
      />
      {formik.touched.selectedCategory?.categoryId &&
        formik.errors.selectedCategory?.categoryId && (
          <FormHelperText error>
            {formik.errors.selectedCategory?.categoryId}
          </FormHelperText>
        )}

      {/* Country Selection */}
      <Typography variant="body1" fontWeight="bold">
        {t("SELECT_COUNTRY")}
      </Typography>
      <CustomSelect
        name="Country"
        icon={AppsIcon}
        isRequired={true}
        options={countries.map((item) => ({
          label: getLocalizedValue(item.name),
          value: item._id,
          currency: getLocalizedValue(item.currency),
        }))}
        multiSelect
        key="Country"
        isMultiSelect={false}
        onSelectChange={handleCountryChange}
        value={result}
      />
      {formik.touched.selectedCountry && formik.errors.selectedCountry && (
        <FormHelperText error>{formik.errors.selectedCountry}</FormHelperText>
      )}

      {/* Post Title */}
      <Typography variant="body1" fontWeight="bold">
        {t("POST_TITLE")}
      </Typography>
      <CustomInput
        fullWidth
        name="title"
        value={formik.values.title}
        onChange={formik.handleChange}
        error={Boolean(formik.touched.title && formik.errors.title)}
        helperText={formik.touched.title && formik.errors.title}
        margin="normal"
      />

      {/* Price Input */}
      <Typography variant="body1" fontWeight="bold">
        {t("PRICE")}
      </Typography>
      <CustomInput
        fullWidth
        type="number"
        name="price"
        value={formik.values.price}
        onChange={formik.handleChange}
        error={Boolean(formik.touched.price && formik.errors.price)}
        helperText={formik.touched.price && formik.errors.price}
        slots={{
          endAdornment: () => <Typography>{formik.values.currency}</Typography>,
        }}
        inputProps={{ min: 1 }} // Disable negative numbers and restrict min to 1
        InputProps={{
          inputMode: "numeric",
          // Disable the step arrows (increment/decrement buttons)
          inputProps: { min: 1 },
          endAdornment: <Typography>{formik.values.currency}</Typography>,
        }}
        margin="normal"
      />

      {/* Description */}
      <Typography variant="body1" fontWeight="bold">
        {t("DESCRIPTION")}
      </Typography>
      <CustomInput
        fullWidth
        name="description"
        value={formik.values.description}
        onChange={formik.handleChange}
        error={Boolean(formik.touched.description && formik.errors.description)}
        helperText={formik.touched.description && formik.errors.description}
        multiline
        rows={4}
        margin="normal"
      />

      {/* Location Selection */}
      <Typography variant="body1" fontWeight="bold">
        {t("SELECT_LOCATION")}
      </Typography>
      <MapComponent
        handleLocationChange={handleLocationChange}
        initialAddress={formik.values.location.address}
        initialLocationName={formik.values.location.locationName}
        initialMarkerPosition={formik.values.location.markerPosition}
      />
      {formik.touched.location?.locationName &&
        formik.errors.location?.locationName && (
          <FormHelperText error>
            {formik.errors.location?.locationName}
          </FormHelperText>
        )}

      {/* Submit Button */}
      <Box display="flex" justifyContent="center" mt={4}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={formik.handleSubmit}
        >
          {t("NEXT")}
        </Button>
      </Box>
    </Box>
  );
};

export default AdInfo;
