import { useNavigate } from "react-router-dom";
import {
  Box,
  Checkbox,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CustomTable from "../../components/common/CustomTable";
import { useParams } from "react-router-dom";
import useUser from "../../hooks/useUser";
import { formatTimestamp } from "../../utils/formatHelper";
import useAd from "../../hooks/useAd";
import getLocalizedValue from "../../utils/getLocalizedValue";
import { set } from "date-fns";

// Columns definition
const columns = [
  { id: "_id", label: "Sn", align: "left" },
  { id: "title", label: "Title", align: "left" },
  { id: "status", label: "Status", align: "left" },
  { id: "time", label: "Joined Date", align: "left" },
  { id: "category", label: "Category", align: "left" },
  { id: "action", label: "Action", align: "right" },
];

function UserDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { getUserById, user, loading } = useUser();
  const { getAdList, adLoading = loading, adList } = useAd();
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState({});
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 10,
  });
  const [pageData, setPageData] = useState({
    totalCount: adList?.totalCount || 0,
    totalPages: adList?.totalPages || 0,
  });

  useEffect(() => {
    if (id) {
      getUserById(id)
        .then((res) => {
          setUserData(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id]);
  useEffect(() => {
    if (id) {
      const payload = {
        page: pagination?.page + 1,
        limit: pagination?.limit,
        filter: JSON.stringify({
          postedBy: id,
        }),
      };
      getAdList(payload);
    }
  }, [pagination.limit, pagination.page, id]);
  useEffect(() => {
    if (adList?.ads?.length > 0) {
      const items = adList?.ads;
      setData(
        items?.map((item, index) => ({
          _id: index + 1,
          title: item.title,
          status: item.status,
          time: formatTimestamp(item?.createdAt, false),
          category: getLocalizedValue(item?.category?.name),
          action: (
            <Button
              size="small"
              variant="outlined"
              //   onClick={() => {
              //     navigate(`/ad/${item?._id}`);
              //   }}
            >
              view
            </Button>
          ),
        }))
      );
    } else {
      setData([]);
    }
    setPageData({
      totalCount: adList?.totalCount || 0,
      totalPages: adList?.totalPages || 0,
    });
  }, [adList]);
  const AccountSettings = ({ data }) => {
    return (
      <Box>
        <Typography variant="h6" gutterBottom>
          Account Settings
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled
              label="Name"
              variant="outlined"
              fullWidth
              value={data?.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled
              label="Email"
              variant="outlined"
              fullWidth
              value={data?.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled
              label="Phone"
              variant="outlined"
              fullWidth
              value={data?.mobile}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled
              label="Role"
              variant="outlined"
              fullWidth
              value={data?.isAdmin ? "Admin" : "User"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Status</InputLabel>
              <Select label="Status" value={data?.status} disabled>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Joined Date"
              variant="outlined"
              fullWidth
              disabled
              value={formatTimestamp(data?.createdAt)}
            />
          </Grid>
        </Grid>
      </Box>
    );
  };
  return (
    <div>
      <Paper elevation={0} sx={{ borderRadius: "0px", padding: "10px" }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          color="primary"
        >
          Users Info
        </Typography>
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "20px" }}
      >
        <ProfileCard data={userData} />
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "20px" }}
      >
        <AccountSettings data={userData} />
      </Paper>
      <Paper elevation={0} sx={{ borderRadius: "0px", mt: "20px" }}>
        <CustomTable
          data={data}
          columns={columns}
          loading={adLoading}
          pagination={{ ...pagination, ...pageData }}
          setPagination={setPagination}
        />
      </Paper>
    </div>
  );
}

const ProfileCard = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [userDetails, setUserDetails] = useState(data);
  const { updateUser, loading } = useUser();

  const handleStatusUpdate = (e) => {
    setUserDetails({ ...userDetails, status: e.target.value });
  };

  const handleSubmit = () => {
    if (data.status === userDetails.status) return;
    updateUser(userDetails?._id, { status: userDetails.status })
      .then((res) => {
        if (res?.success) {
          setUserDetails(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setUserDetails(data);
  }, [data]);
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection={isMobile ? "column" : "row"}
      p={2}
      width="100%"
      gap={2}
    >
      {/* Left Section */}
      <Box flex={isMobile ? "1" : "0.6"} display="flex" gap={1.5}>
        <Avatar
          alt="User Name"
          src={userDetails?.profilePicture || "https://via.placeholder.com/150"} // Replace with the actual image URL
          sx={{ width: 80, height: 80 }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6" component="h2">
            {userDetails?.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Joined: {formatTimestamp(userDetails?.createdAt)}
          </Typography>
        </Box>
      </Box>

      {/* Right Section */}
      <Box
        flex={isMobile ? "1" : "0.4"}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="space-between"
        gap={2}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: "10px",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <FormControl fullWidth>
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                label="Status"
                defaultValue="active"
                value={userDetails?.status}
                onChange={handleStatusUpdate}
                size="small"
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
                <MenuItem value="busy">Busy</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ width: "50%" }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
            >
              Update
            </Button>
          </Box>
        </Box>

        <Button variant="contained" color="primary" fullWidth>
          Send Message
        </Button>
      </Box>
    </Box>
  );
};

const AccountSettings = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      p={1}
      borderRadius={2}
      width="100%"
      margin="auto"
      gap={3}
    >
      {/* Center-aligned Heading */}
      <Typography variant="h6" textAlign="center" color="primary" gutterBottom>
        Account Settings
      </Typography>

      {/* Responsive Grid Container */}
      <Grid container spacing={2} direction={isMobile ? "column" : "row"}>
        {/* Left Section */}
        <Grid item xs={12} sm={6}>
          <Typography variant="body2">Name</Typography>
          <TextField
            value={data?.name} // Replace with actual value or state variable
            fullWidth
            size="small"
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            margin="normal"
            style={{ marginTop: "0px", marginBottom: "10px" }}
          />
          <Typography variant="body2">Emial</Typography>

          <TextField
            size="small"
            value={data.email} // Replace with actual value or state variable
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            margin="normal"
            style={{ marginTop: "0px", marginBottom: "10px" }}
          />
          <Typography variant="body2">Phone number</Typography>
          <Box display="flex" gap={1}>
            <TextField
              size="small"
              value={data?.countryCode} // Replace with actual value or state variable
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              sx={{ width: "30%" }}
            />
            <TextField
              size="small"
              value={data?.mobile} // Replace with actual value or state variable
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
            />
          </Box>
        </Grid>

        {/* Right Section */}
        <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            paddingX={5}
            paddingY={2}
          >
            <Button variant="contained" color="primary" fullWidth>
              Change Password
            </Button>
            <Button variant="outlined" color="error" fullWidth>
              Delete Account
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserDetails;
