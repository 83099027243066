import React from 'react';
import { Slider, Box, Typography, Grid, FormControl, TextField } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';

const CustomInputRange = ({ name, icon: Icon, isRequired, onInputChange }) => {
    const handleChange = (event, newValue) => {
        onInputChange(name, newValue);
    };

    return (
        <Box sx={{ backgroundColor: 'white', cursor: 'pointer', p: 1, borderRadius: '10px', my: 1 }}>
        <Grid container justifyContent="space-between" spacing={2}  >
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center', }}>
                    {Icon && <Icon color="primary" />}
                    <Typography variant="body1" fontWeight="bold">
                        {name}
                        {isRequired && (
                            <Typography component="span" color="red">
                                *
                            </Typography>
                        )}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <TextField size='small' fullWidth />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <TextField size='small' fullWidth />
                </FormControl>
            </Grid>
        </Grid>
    </Box>
    );
};

export default CustomInputRange;
