import React, { useState } from "react";
import { Box, Button, Typography, IconButton, Grid } from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import useCreatePost from "../../hooks/useCreatePost"; // Custom hook for saving Redux state
import { useImageContext } from "../../utils/ImageContext";
import { useTranslation } from "react-i18next";

const ImageUploadStep = (props) => {
  const { handleNext, handleBack } = props;
  const { t } = useTranslation();
  const {images,addImages} = useImageContext(); // Use the context
 
  const maxImages = 8; 

  // Handle file input change
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);

    // Check if no files are selected
    if (files.length === 0) return;

    // Prevent adding more than the allowed number of images
    if (images.length + files.length > maxImages) {
      alert(`You can upload a maximum of ${maxImages} images.`);
      return;
    }

    // Create an array to store new images
    const newImages = [...images];
    let loadedImages = [];
    // Loop through each file and read as Data URL (for preview)
    files.forEach((file, index) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const newImage = { src: reader.result, file }; // Store base64 and file object
        loadedImages.push(newImage);

        // Check if it's the last image to load
        if (loadedImages.length === files.length) {
          // Combine the loaded images with existing images
          const updatedImages = [...newImages, ...loadedImages];

          // Set images state
          addImages(updatedImages);
        }
      };

      reader.readAsDataURL(file); // Start reading file
    });
  };

  // Handle delete image
  const handleDeleteImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);

    addImages(newImages);
  };

  // Proceed to the next step and save data to Redux
  const goToNext = () => {
    if (images.length > 0) {
      handleNext();
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h6" gutterBottom>
        {t("SELECT_IMAGES")}
      </Typography>

      <Grid container spacing={2}>
        {images?.map((image, index) => (
          <Grid item xs={6} sm={4} md={3} key={index}>
            <Box
              sx={{
                position: "relative",
                aspectRatio: "1 / 1",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 1,
                overflow: "hidden",
              }}
            >
              <img
                src={image.src}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                alt={`${index + 1}`}
              />
              <IconButton
                sx={{ position: "absolute", top: 8, right: 8, color: "white" }}
                onClick={() => handleDeleteImage(index)}
                color="primary"
              >
                <DeleteIcon />
              </IconButton>

              {/* Mark as cover button or label */}
              {index === 0 ? (
                <Typography
                  sx={{
                    position: "absolute",
                    bottom: 8,
                    left: 8,
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    color: "white",
                    padding: "4px 8px",
                    borderRadius: "4px",
                  }}
                >
                  Cover Image
                </Typography>
              ) : (
                <></>
              )}
            </Box>
          </Grid>
        ))}

        {/* Add image button */}
        {images.length < maxImages && (
          <Grid item xs={6} sm={4} md={3}>
            <Box
              sx={{
                position: "relative",
                aspectRatio: "1 / 1",
                backgroundColor: "grey.300",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 1,
                overflow: "hidden",
              }}
            >
              <IconButton
                color="primary"
                onClick={() =>
                  document.getElementById("imageUploadInput").click()
                }
              >
                <AddIcon />
              </IconButton>
              <input
                type="file"
                id="imageUploadInput"
                accept="image/*"
                style={{ display: "none" }}
                multiple
                onChange={handleFileChange}
              />
            </Box>
          </Grid>
        )}
        {Array.from({ length: maxImages - (images.length + 1) }).map(
          (_, index) => (
            <Grid item xs={6} sm={4} md={3}>
              <Box
                sx={{
                  position: "relative",
                  aspectRatio: "1 / 1",
                  backgroundColor: "grey.300",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 1,
                  overflow: "hidden",
                }}
              ></Box>
            </Grid>
          )
        )}
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
        <Button variant="outlined" color="primary" onClick={handleBack}>
          {t("BACK")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={goToNext}
          disabled={images.length === 0} // Disable if no image is uploaded
        >
          {t("NEXT")}
        </Button>
      </Box>
    </Box>
  );
};

export default ImageUploadStep;
