import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Radio,
  Button,
  CircularProgress,
} from "@mui/material";
import useCreatePost from "../../hooks/useCreatePost";
import api from "../../utils/API";
import commonService from "../../services/common.api";
import { useTranslation } from "react-i18next";

const PlanOption = ({ plan, selectedPlan, handlePlanSelect }) => (
  <Paper sx={{ padding: 2, marginTop: 2, boxShadow: 3, position: "relative" }}>
    <Typography variant="h6" fontWeight="bold">
      {plan.description}
    </Typography>
    {plan.description && (
      <Typography variant="body2" color="textSecondary">
        {plan.description}
      </Typography>
    )}
    <Box sx={{ display: "flex", gap: "20px" }}>
      <Typography
        variant="h6"
        sx={{
          color: "textSecondary",
          fontWeight: "bold",
          mt: 1,
          textDecoration: "line-through",
        }}
      >
        {plan.price}
      </Typography>
      <Typography
        variant="h5"
        sx={{ color: "primary.main", fontWeight: "bold", mt: 1 }}
      >
        {plan.discountPrice} KWD
      </Typography>
    </Box>

    {/* Radio button for selecting the plan */}
    <Radio
      checked={selectedPlan._id === plan._id}
      onChange={() => handlePlanSelect(plan)}
      value={plan._id}
      sx={{ position: "absolute", top: 10, right: 10 }}
    />
  </Paper>
);

const SelectPlan = (props) => {
  const { t } = useTranslation();
  const { handleBack, handleNext } = props;
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const { saveData } = useCreatePost();

  // Fetch plans from API
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const data = await commonService.fetchPlans();
        if (data.success) {
          setPlans(data.data);
        }
      } catch (error) {
        console.error("Failed to fetch payment plans", error);
      } finally {
        setLoading(false); // Stop loading spinner
      }
    };
    fetchPlans();
  }, []);

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  const handleNextStep = () => {
    if (Object.keys(selectedPlan).length < 1) {
      alert("Please select a payment plan.");
      return;
    }
    saveData("paymentPlan", selectedPlan);
    handleNext();
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Typography
        variant="h5"
        textAlign="center"
        fontWeight="bold"
        sx={{ color: "primary.main", mb: 2 }}
      >
        {t("SELECT_PLAN")}
      </Typography>

      {plans.map((group, index) => (
        <Box key={index} sx={{ marginBottom: 4, color: "primary.form" }}>
          <Typography
            variant="h6"
            align="center"
            sx={{
              backgroundColor: group.titleBackgroundColor,
              color: "#FFFFFF",
              fontWeight: "bold",
              borderRadius: "5px",
            }}
          >
            {group.paymentPlanName}
          </Typography>

          <Grid container>
            {group.items.map((plan, planIndex) => (
              <Grid item xs={12} key={planIndex}>
                <PlanOption
                  plan={plan}
                  selectedPlan={selectedPlan}
                  handlePlanSelect={handlePlanSelect}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
        <Button variant="outlined" color="primary" onClick={handleBack}>
          {t("BACK")}
        </Button>
        <Button variant="contained" color="primary" onClick={handleNextStep}>
          {t("NEXT")}
        </Button>
      </Box>
    </Box>
  );
};

export default SelectPlan;
