import api from "../utils/API";

// Base URL for your API
export const additionalDetailsService = {
  // additional details api's
  fetchAdditionalDetails: async (payload) => {
    const response = await api.get("/admin/additional-details", {
      params: payload,
    });
    return response.data;
  },
  fetchAdditionalDetailsById: async (id) => {
    const response = await api.get(`/admin/additional-details/${id}`);
    return response.data;
  },
  createAdditionalDetails: async (payload) => {
    const response = await api.post("/admin/additional-details", payload);
    return response.data;
  },
  updateAdditionalDetails: async (id, payload) => {
    const response = await api.put(`/admin/additional-details/${id}`, payload);
    return response.data;
  },
  deleteAdditionalDetails: async (payload) => {
    const response = await api.delete("/admin/additional-details/delete", {
      data: payload,
    });
    return response.data;
  },
};
