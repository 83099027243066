import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectMenuError,
  selectMenuItems,
  setMenuItems,
} from "../store/menuSlice";
import { menuService } from "../services/menu.api";

const useMenu = () => {
  const dispatch = useDispatch();
  const menuItems = useSelector(selectMenuItems);
  const menu = useSelector(selectMenuItems);
  const error = useSelector(selectMenuError);
  const [loading, setLoading] = useState(false);

  const getMenuItem = async (payload) => {
    setLoading(true);
    const data = await menuService.fetchMenuItems(payload);
    if (data.success) {
      dispatch(setMenuItems(data));
    }
    setLoading(false);
    return data;
  };

  const getMenu = async (categoryId) => {
    setLoading(true);
    const data = await menuService.fetchMenu(categoryId);
    if (data.success) {
      dispatch(setMenuItems(data));
    }
    setLoading(false);
    return data;
  };

  const updateMenu = async (payload) => {
    setLoading(true);
    const data = await menuService.updateMenu(payload);
    setLoading(false);
    return data;
  };

  return {
    getMenuItem,
    getMenu,
    menu,
    menuItems,
    updateMenu,
    loading,
    error,
  };
};

export default useMenu;
