import { createSlice } from "@reduxjs/toolkit";

// Create a slice for categories
const specificationSlice = createSlice({
  name: "specification",
  initialState: {
    specifications: [],
    specification: {},
    loading: false,
    error: null,
  },
  reducers: {
    setSpecifications: (state, action) => {
      state.specifications = action.payload.data || [];
      state.loading = false;
    },
    setSpecification: (state, action) => {
      state.specification = action.payload.data || [];
      state.loading = false;
    },
  },
});

export const selectSpecifications = (state) => state.specification.specifications;
export const selectSpecification = (state) => state.specification.specification;
export const selectSpecificationError = (state) => state.specification.error;
// Export the reducer to include in the store
export const { setSpecifications, setSpecification } =
  specificationSlice.actions;
export default specificationSlice.reducer;
