import api from "../utils/API";

// Base URL for your API
export const menuService = {
  fetchMenuItems: async (payload) => {
    const response = await api.get("/admin/category/sub-categories/null", {
      params: payload,
    });
    return response.data;
  },

  fetchMenu: async (categoryId) => {
    const response = await api.get(
      `/admin/category/sub-categories/${categoryId}`
    );
    return response.data;
  },

  updateMenu: async (payload) => {
    const response = await api.patch(
      "/admin/category/update-categories",
      payload
    );
    return response.data;
  },
};
