import { createSlice } from "@reduxjs/toolkit";

// Create a slice for categories
const reportSlice = createSlice({
  name: "report",
  initialState: {
    reportItems: [],
    report: {},
    loading: false,
    error: null,
  },
  reducers: {
    setReportItems: (state, action) => {
      state.reportItems = action.payload.data || [];
      state.loading = false;
    },
    setReport: (state, action) => {
      state.report = action.payload.data || [];
      state.loading = false;
    },
  },
});

// Export the loading and error selectors if needed
export const selectReportLoading = (state) => state.report.loading;
export const selectReportError = (state) => state.report.error;
export const selectReportItems = (state) => state.report.reportItems;
export const selectReport = (state) => state.report.report;

// Export the reducer to include in the store
export const { setReport, setReportItems } = reportSlice.actions;
export default reportSlice.reducer;
