import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Paper,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import 'react-quill/dist/quill.snow.css';

const ReactQuill = import('react-quill');

export default function QUillEditer() {
  const [enabled, setEnabled] = useState('enable');
  const [language, setLanguage] = useState('english');
  const [editorContent, setEditorContent] = useState('First-time users enjoy 90 days of free ad publishing');
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleEnableChange = (event) => {
    setEnabled(event.target.value);
  };

  const handleLanguageChange = (
    event,
    newLanguage,
  ) => {
    if (newLanguage !== null) {
      setLanguage(newLanguage);
    }
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', p: 3 }}>
      {/* Enable/Disable Radio Buttons */}
      <FormControl component="fieldset" sx={{ width: '100%', mb: 3 }}>
        <RadioGroup
          row
          name="enable-disable"
          value={enabled}
          onChange={handleEnableChange}
          sx={{
            justifyContent: 'center',
            '& .MuiFormControlLabel-root': {
              mx: 2,
            },
          }}
        >
          <FormControlLabel
            value="enable"
            control={
              <Radio
                sx={{
                  color: '#F06B5B',
                  '&.Mui-checked': {
                    color: '#F06B5B',
                  },
                }}
              />
            }
            label="Enable"
          />
          <FormControlLabel
            value="disable"
            control={
              <Radio
                sx={{
                  color: '#F06B5B',
                  '&.Mui-checked': {
                    color: '#F06B5B',
                  },
                }}
              />
            }
            label="Disable"
          />
        </RadioGroup>
      </FormControl>

      {/* Title */}
      <Typography
        variant="h6"
        align="center"
        sx={{ mb: 2 }}
      >
        Promotional Plan Description
      </Typography>

      {/* Language Toggle */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
        <ToggleButtonGroup
          value={language}
          exclusive
          onChange={handleLanguageChange}
          aria-label="language selection"
        >
          <ToggleButton
            value="english"
            aria-label="english"
            sx={{
              px: 4,
              bgcolor: language === 'english' ? '#F06B5B' : 'transparent',
              color: language === 'english' ? 'white' : 'inherit',
              '&:hover': {
                bgcolor: language === 'english' ? '#F06B5B' : 'rgba(0, 0, 0, 0.04)',
              },
              '&.Mui-selected': {
                bgcolor: '#F06B5B',
                color: 'white',
                '&:hover': {
                  bgcolor: '#F06B5B',
                },
              },
            }}
          >
            English
          </ToggleButton>
          <ToggleButton
            value="arabic"
            aria-label="arabic"
            sx={{
              px: 4,
              bgcolor: language === 'arabic' ? '#F06B5B' : 'transparent',
              color: language === 'arabic' ? 'white' : 'inherit',
              '&:hover': {
                bgcolor: language === 'arabic' ? '#F06B5B' : 'rgba(0, 0, 0, 0.04)',
              },
              '&.Mui-selected': {
                bgcolor: '#F06B5B',
                color: 'white',
                '&:hover': {
                  bgcolor: '#F06B5B',
                },
              },
            }}
          >
            Arabic
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {/* Text Editor */}
      <Paper elevation={1} sx={{ p: 1 }}>
        <ReactQuill
          theme="snow"
          value={editorContent}
          onChange={setEditorContent}
          modules={modules}
          formats={formats}
          style={{ height: '250px', marginBottom: '50px' }}
        />
      </Paper>
    </Box>
  );
}