import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { specificationService } from "../services/specification.api";
import {
  selectSpecification,
  selectSpecificationError,
  selectSpecifications,
  setSpecification,
  setSpecifications,
} from "../store/specificationSlice";

const useSpecification = () => {
  const dispatch = useDispatch();
  const specifications = useSelector(selectSpecifications);
  const specification = useSelector(selectSpecification);
  const error = useSelector(selectSpecificationError);
  const [loading, setLoading] = useState(false);

  const getSpecificationsList = async (payload) => {
    setLoading(true);
    const data = await specificationService.fetchSpecificationsList(payload);
    if (data.success) {
      dispatch(setSpecifications(data));
    }
    setLoading(false);
    return data;
  };

  const getSpecificationById = async (id) => {
    setLoading(true);
    const data = await specificationService.fetchSpecificationById(id);
    if (data.success) {
      dispatch(setSpecification(data));
    }
    setLoading(false);
    return data;
  };

  const createSpecifications = async (payload) => {
    setLoading(true);
    const data = await specificationService.createSpecifications(payload);
    setLoading(false);
    return data;
  };

  const updateSpecifications = async (id, payload) => {
    setLoading(true);
    const data = await specificationService.updateSpecifications(id, payload);
    setLoading(false);
    return data;
  };

  const deleteSpecifications = async (payload) => {
    setLoading(true);
    const data = await specificationService.deleteSpecifications(payload);
    setLoading(false);
    return data;
  };

  return {
    getSpecificationsList,
    specifications,
    createSpecifications,
    getSpecificationById,
    updateSpecifications,
    deleteSpecifications,
    specification,
    loading,
    error,
  };
};

export default useSpecification;
