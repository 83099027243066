import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomTable from "../../components/common/CustomTable";
import CustomTabPanel from "../../components/common/CustomTabPanel";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import useSpecification from "../../hooks/useSpecifications";
import { useState } from "react";
import getLocalizedValue from "../../utils/getLocalizedValue";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

// Columns definition
const columns = [
  { id: "id", label: <Checkbox />, align: "left" },
  { id: "name", label: "Specification List", align: "left" },
  { id: "action", label: "Action", align: "right" },
];

function SpecificationEditPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [item, setItem] = useState({});
  const [listItems, setListItems] = useState([]);
  const [listToDisplay, setListToDisplay] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const [nameValue, setNameValue] = useState(0);
  const [iconImage, setIconImage] = useState(null);
  const [dialogTabValue, setDialogTabValue] = useState(0);
  const {
    createSpecifications,
    getSpecificationById,
    specification,
    updateSpecifications,
    deleteSpecifications,
  } = useSpecification();
  const [value, setValue] = useState(
    specification?.type === "Dropdown List"
      ? 0
      : specification?.type === "Input Number Range"
      ? 1
      : 2
  );

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleNameValChange = (newValue) => {
    setNameValue(newValue);
  };

  const formik = useFormik({
    initialValues: {
      name: {
        en: "",
        ar: "",
      },
    },
    onSubmit: async (values) => {
      try {
        const formData = prepareFormData(values);

        const response = id
          ? await updateSpecifications(id, formData)
          : await createSpecifications(formData);

        if (response.success) {
          formik.resetForm(); // Reset form after successful submission
          navigate("/specification"); // Navigate to the category list page
        } else {
          alert("Oops.. Something went wrong.");
        }
      } catch (error) {
        console.error("Error during form submission:", error);
        alert("Oops.. Something went wrong.");
      }
    },
  });

  const prepareFormData = (values) => {
    const formData = new FormData();
    if (iconImage) {
      formData.append("iconImage", iconImage[0]);
    }
    formData.append("name", JSON.stringify(values.name));
    formData.append(
      "type",
      value === 0
        ? "Dropdown List"
        : value === 1
        ? "Input Number Range"
        : "Input"
    );
    if (listItems.length > 0 && value === 0) {
      const listItemsWithOrder = listItems.map((item, index) => ({
        name: item,
        displayOrder: index + 1,
      }));
      formData.append("listItems", JSON.stringify(listItemsWithOrder));
    } else if (value === 1) {
      formData.append("range", JSON.stringify(values.range));
    } else if (value === 2) {
      formData.append("inputProps", values.inputProps);
    }
    return formData;
  };

  const handleFileChange = (event) => {
    const file = event.target.files;
    if (file) {
      setIconImage([...file]);
    }
  };
  const handleDeleteCategory = () => {
    if (id) {
      if (!window.confirm("Are you sure you want to delete this category?")) {
        return;
      }

      const payload = { ids: [id] };

      deleteSpecifications(payload).then((response) => {
        if (response.success) {
          navigate("/specification");
        } else {
          alert("Oops.. Something went wrong.");
        }
      });
    } else {
      navigate("/specification");
    }
  };

  const handleClose = () => {
    setItem({});
    setOpen(false);
  };

  const handleSelection = (itemEn) => {
    setSelectedItem((prevState) => {
      if (prevState.includes(itemEn)) {
        return prevState.filter((enItem) => enItem !== itemEn);
      } else {
        return [...prevState, itemEn];
      }
    });
  };

  const handleSave = () => {
    if (!item.en || item.en.trim() === "") {
      alert("The English name (en) is required.");
      return;
    }

    const isDuplicate = listItems.some(
      (listItem, index) =>
        listItem.en?.toLowerCase() === item.en?.toLowerCase() &&
        index !== editingIndex
    );

    if (isDuplicate) {
      alert("Duplicate entry is not allowed.");
      return;
    }

    const updatedListItems = [...listItems];

    if (editingIndex !== null) {
      updatedListItems[editingIndex] = {
        name: item,
      };

      setListToDisplay(
        updatedListItems.map((listItem, index) => ({
          id: (
            <Checkbox
              checked={selectedItem.includes(listItem?.name?.en)}
              onChange={() => handleSelection(listItem?.name?.en)}
            />
          ),
          name: getLocalizedValue(listItem?.name),
          action: (
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() => handleEdit(index)} // Call edit handler
            >
              Edit
            </Button>
          ),
        }))
      );
    } else {
      // Add new item if not editing
      updatedListItems.push(item);

      setListToDisplay([
        ...listToDisplay,
        {
          id: (
            <Checkbox
              checked={selectedItem.includes(item.en)}
              onChange={() => handleSelection(item.en)}
            />
          ),
          name: getLocalizedValue(item),
          action: (
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() => handleEdit(updatedListItems.length - 1)} // Call edit handler
            >
              Edit
            </Button>
          ),
        },
      ]);
    }

    setListItems(updatedListItems);
    setItem({ en: "", ar: "" });
    setEditingIndex(null); // Reset editing index
    setOpen(false);
  };

  const handleEdit = (index) => {
    const itemToEdit = listItems[index];
    setItem(itemToEdit?.name);
    setEditingIndex(index);
    setOpen(true);
  };

  useEffect(() => {
    if (id) {
      getSpecificationById(id);
    }
  }, [id]);

  useEffect(() => {
    if (specification && id) {
      formik.setValues({
        name: {
          en: specification?.name?.en,
          ar: specification?.name?.ar,
        },
        listItems: specification?.listItems,
        type: specification?.type,
        range: specification?.range,
        inputProps: specification?.inputProps,
      });
      setValue(
        specification?.type === "Dropdown List"
          ? 0
          : specification?.type === "Input Number Range"
          ? 1
          : 2
      );
      if (specification.iconImage) {
        setIconImage(specification.iconImage);
      }

      if (specification?.listItems?.length > 0) {
        setListItems(specification.listItems);
        setListToDisplay(
          specification.listItems.map((item, index) => ({
            id: (
              <Checkbox
                checked={selectedItem.includes(item.en)}
                onChange={() => handleSelection(item.en)}
              />
            ),
            name: getLocalizedValue(item.name),
            action: (
              <Button
                size="small"
                variant="outlined"
                onClick={() => handleEdit(index)} // Pass index to edit handler
              >
                Edit
              </Button>
            ),
          }))
        );
      }
    }
  }, [specification, selectedItem]);
  return (
    <Box sx={{ width: "100%" }}>
      <Paper elevation={0} sx={{ borderRadius: "0px", padding: "10px" }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          color="primary"
        >
          Add / Edit Specification item
        </Typography>
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", padding: "20px", mt: "20px" }}
      >
        <Container maxWidth="sm">
          <Box style={{ display: "flex", width: "100%" }}>
            <Button
              variant={nameValue === 0 ? "contained" : "text"}
              style={{
                borderRadius: "0px",
                width: "50%",
                fontSize: "20px",
                fontWeight: "bold",
              }}
              onClick={() => handleNameValChange(0)}
            >
              English
            </Button>
            <Button
              variant={nameValue === 1 ? "contained" : "text"}
              style={{
                borderRadius: "0px",
                width: "50%",
                fontSize: "20px",
                fontWeight: "bold",
              }}
              onClick={() => handleNameValChange(1)}
            >
              Arabic
            </Button>
          </Box>
          <CustomTabPanel value={nameValue} index={0}>
            <Typography variant="body1" fontWeight="bold">
              English
            </Typography>
            <TextField
              fullWidth
              size="small"
              name="name.en"
              value={formik.values?.name?.en}
              onChange={formik.handleChange}
            />
          </CustomTabPanel>
          <CustomTabPanel value={nameValue} index={1}>
            <Typography variant="body1" fontWeight="bold">
              Arabic
            </Typography>
            <TextField
              fullWidth
              size="small"
              name="name.ar"
              value={formik.values?.name?.ar}
              onChange={formik.handleChange}
            />
          </CustomTabPanel>
        </Container>
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              gap: 5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography fontWeight="bold" variant="body2">
                Icon Image{" "}
              </Typography>
              <Box
                sx={{
                  height: "70px",
                  width: "70px",
                  backgroundColor: "primary.main",
                  borderRadius: "10px",
                }}
              >
                {iconImage && (
                  <img
                    style={{
                      height: "70px",
                      width: "70px",
                      borderRadius: "10px",
                      objectFit: "contain",
                    }}
                    src={
                      typeof iconImage === "string"
                        ? iconImage
                        : URL.createObjectURL(iconImage[0])
                    }
                    alt="icon preview"
                  />
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
              >
                Upload Image
                <VisuallyHiddenInput
                  type="file"
                  onChange={handleFileChange}
                  multiple
                />
              </Button>
              <Typography fontWeight="bold" variant="body2">
                Size: 100 x 100px{" "}
              </Typography>
            </Box>
          </Box>
        </Container>
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          color="primary"
        >
          Select Specification Type
        </Typography>
        <Container maxWidth="md">
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 6,
              width: "100%",
              margin: "20px 0px",
            }}
          >
            <Button
              variant={value === 0 ? "contained" : "outlined"}
              style={{
                borderRadius: "25px",
                width: "30%",
                fontSize: "14px",
                fontWeight: "bold",
              }}
              onClick={() => handleChange(0)}
            >
              Dropdown List
            </Button>
            <Button
              variant={value === 1 ? "contained" : "outlined"}
              style={{
                borderRadius: "25px",
                width: "30%",
                fontSize: "14px",
                fontWeight: "bold",
              }}
              onClick={() => handleChange(1)}
            >
              Input Number Range
            </Button>
            <Button
              variant={value === 2 ? "contained" : "outlined"}
              style={{
                borderRadius: "25px",
                width: "30%",
                fontSize: "14px",
                fontWeight: "bold",
              }}
              onClick={() => handleChange(2)}
            >
              Input
            </Button>
          </Box>
        </Container>
        <CustomTabPanel value={value} index={0}>
          <Typography variant="body1" fontWeight="bold">
            Select List items
          </Typography>
          <CustomTable
            data={listToDisplay}
            columns={columns}
            hidePagination={true}
          />
          <Container maxWidth="sm">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2rem",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px" }}
                size="large"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Add List Item
              </Button>
              <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle>{"Add Item"}</DialogTitle>
                <DialogContent sx={{ width: "500px" }}>
                  <Container>
                    <Box style={{ display: "flex", width: "100%" }}>
                      <Button
                        variant={dialogTabValue === 0 ? "contained" : "text"}
                        style={{
                          borderRadius: "0px",
                          width: "50%",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                        onClick={() => setDialogTabValue(0)}
                      >
                        English
                      </Button>
                      <Button
                        variant={dialogTabValue === 1 ? "contained" : "text"}
                        style={{
                          borderRadius: "0px",
                          width: "50%",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                        onClick={() => setDialogTabValue(1)}
                      >
                        Arabic
                      </Button>
                    </Box>
                    <CustomTabPanel value={dialogTabValue} index={0}>
                      <Typography variant="body1" fontWeight="bold">
                        English
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        name="name.en"
                        value={item?.en}
                        onChange={(e) => {
                          setItem({ ...item, en: e.target.value });
                        }}
                      />
                    </CustomTabPanel>
                    <CustomTabPanel value={dialogTabValue} index={1}>
                      <Typography variant="body1" fontWeight="bold">
                        Arabic
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        name="name.ar"
                        value={item?.ar}
                        onChange={(e) =>
                          setItem({ ...item, ar: e.target.value })
                        }
                      />
                    </CustomTabPanel>
                  </Container>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={handleSave}>Save</Button>
                </DialogActions>
              </Dialog>
              <Button
                variant="contained"
                color="secondary"
                sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px" }}
                size="large"
              >
                Delete List Item
              </Button>
            </Box>
          </Container>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Container maxWidth="sm">
            <Typography
              variant="body1"
              fontWeight="bold"
              color="primary"
              textAlign="center"
              style={{ margin: "20px 0px" }}
            >
              Enter Range Values{" "}
            </Typography>
            <Box
              style={{
                display: "flex",
                width: "100%",
                gap: "20px",
                paddingY: "20px",
              }}
            >
              <Box style={{ width: "100%" }}>
                <Typography variant="body2" fontWeight="bold">
                  Range value from
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="range.min"
                  onChange={formik.handleChange}
                  value={formik.values?.range?.min}
                />
              </Box>
              <Box style={{ width: "100%" }}>
                <Typography variant="body2" fontWeight="bold">
                  Range value from
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="range.max"
                  onChange={formik.handleChange}
                  value={formik.values?.range?.max}
                />
              </Box>
            </Box>
          </Container>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Container maxWidth="sm">
            <Typography
              variant="body1"
              fontWeight="bold"
              color="primary"
              textAlign="center"
              style={{ margin: "20px 0px" }}
            >
              Select Values{" "}
            </Typography>
            <Box
              style={{
                display: "flex",
                width: "100%",
                gap: "20px",
                paddingY: "20px",
              }}
            >
              <Box style={{ width: "100%" }}>
                <FormControl fullWidth>
                  <Typography fontWeight="bold" variant="body2">
                    Input Props{" "}
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formik.values.inputProps}
                    name="inputProps"
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={"text"}>Text</MenuItem>
                    <MenuItem value={"number"}>Number</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Container>
        </CustomTabPanel>
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px" }}
              size="large"
              onClick={formik.handleSubmit}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px" }}
              size="large"
              onClick={handleDeleteCategory}
            >
              Delete Category
            </Button>
          </Box>
        </Container>
      </Paper>
    </Box>
  );
}

export default SpecificationEditPage;
