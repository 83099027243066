import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTable from "../../components/common/CustomTable";
import getLocalizedValue from "../../utils/getLocalizedValue";
import CustomTabPanel from "../../components/common/CustomTabPanel";
import useAdditionalDetails from "../../hooks/useAdditionalDetails";

// Columns definition
const columns = [
  { id: "id", label: <Checkbox />, align: "left" },
  { id: "name", label: "Additional Detail List ", align: "left" },
  { id: "action", label: "Action", align: "right" },
];

function AdditionalDetails() {
  const {
    getAdditionalDetails,
    additionalDetails,
    loading,
    createAdditionalDetails,
    updateAdditionalDetails,
    deleteAdditionalDetails,
  } = useAdditionalDetails();

  const [data, setData] = useState([]);
  const [dialogTabValue, setDialogTabValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({
    page: additionalDetails?.page - 1 || 0,
    limit: additionalDetails?.limit || 10,
  });
  const [pageData, setPageData] = useState({
    totalCount: additionalDetails?.totalCount,
    totalPages: additionalDetails?.totalPages,
  });
  const [search, setSearch] = useState("");
  const [item, setItem] = useState({});
  const [editId, setEditId] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };
  useEffect(() => {
    const payload = {
      page: pagination?.page + 1,
      limit: pagination?.limit,
      search: "",
    };
    getAdditionalDetails(payload);
  }, [pagination?.page, pagination?.limit]);

  useEffect(() => {
    if (additionalDetails?.additionalDetails?.length > 0) {
      setData(
        additionalDetails?.additionalDetails?.map((item) => ({
          id: (
            <Checkbox
              checked={selectedIds.includes(item._id)}
              onChange={() => handleCheckboxChange(item._id)}
            />
          ),
          name: getLocalizedValue(item.name),
          action: (
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                setItem(item.name);
                setEditId(item._id);
                setOpen(true);
              }}
            >
              Edit
            </Button>
          ),
        }))
      );
    }
    setPageData({
      totalCount: additionalDetails?.totalCount || 0,
      totalPages: additionalDetails?.totalPages || 0,
    });
  }, [additionalDetails, handleCheckboxChange]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = () => {
    setPagination({ ...pagination, page: 0 });
    const payload = {
      page: 1,
      limit: pagination?.limit,
      search: search,
    };
    getAdditionalDetails(payload);
  };

  const handleSave = async () => {
    if (item.en || item.ar) {
      const payload = {
        name: item,
      };
      setPagination({ ...pagination, page: 0 });
      if (editId) {
        await updateAdditionalDetails(editId, payload);
      } else {
        await createAdditionalDetails(payload);
      }
      const payload2 = {
        page: pagination?.page + 1,
        limit: pagination?.limit,
        search: "",
      };
      getAdditionalDetails(payload2);
      setItem({});
      setOpen(false);
    }
  };

  const handleDelete = async () => {
    const payload = {
      ids: selectedIds,
    };
    await deleteAdditionalDetails(payload);
    setPagination({ ...pagination, page: 0 });
    const payload2 = {
      page: pagination?.page + 1,
      limit: pagination?.limit,
      search: "",
    };
    getAdditionalDetails(payload2);
    setSelectedIds([]);
  };
  return (
    <div>
      <Paper elevation={0} sx={{ borderRadius: "0px", padding: "10px" }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          color="primary"
        >
          Additional Details List
        </Typography>
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "20px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px" }}
              size="large"
              onClick={() => setOpen(true)}
            >
              Add item
            </Button>
            <Dialog
              open={open}
              // TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"Add Item"}</DialogTitle>
              <DialogContent sx={{ width: "500px" }}>
                <Container>
                  <Box style={{ display: "flex", width: "100%" }}>
                    <Button
                      variant={dialogTabValue === 0 ? "contained" : "text"}
                      style={{
                        borderRadius: "0px",
                        width: "50%",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                      onClick={() => setDialogTabValue(0)}
                    >
                      English
                    </Button>
                    <Button
                      variant={dialogTabValue === 1 ? "contained" : "text"}
                      style={{
                        borderRadius: "0px",
                        width: "50%",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                      onClick={() => setDialogTabValue(1)}
                    >
                      Arabic
                    </Button>
                  </Box>
                  <CustomTabPanel value={dialogTabValue} index={0}>
                    <Typography variant="body1" fontWeight="bold">
                      English
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      name="name.en"
                      value={item.en}
                      onChange={(e) => {
                        setItem({ ...item, en: e.target.value });
                      }}
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={dialogTabValue} index={1}>
                    <Typography variant="body1" fontWeight="bold">
                      Arabic
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      name="name.ar"
                      value={item.ar}
                      onChange={(e) => setItem({ ...item, ar: e.target.value })}
                    />
                  </CustomTabPanel>
                </Container>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
              </DialogActions>
            </Dialog>
            <Button
              variant="contained"
              color="secondary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px" }}
              size="large"
              onClick={() => {
                if (!window.confirm("Are you sure you want to delete this?")) {
                  return;
                }
                handleDelete();
              }}
            >
              Delete
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "20px",
            }}
          >
            <TextField
              fullWidth
              sx={{ mx: "5px" }}
              size="small"
              onChange={(e) => {
                e.preventDefault();
                setSearch(e.target.value);
              }}
            />

            <Button
              variant="contained"
              color="primary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px" }}
              size="medium"
              onClick={handleSearch}
            >
              Search
            </Button>
          </Box>
        </Container>
      </Paper>

      <Paper elevation={0} sx={{ borderRadius: "0px", mt: "20px" }}>
        <CustomTable
          data={data}
          columns={columns}
          loading={loading}
          pagination={{ ...pagination, ...pageData }}
          setPagination={setPagination}
        />
      </Paper>
    </div>
  );
}

export default AdditionalDetails;
