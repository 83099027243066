import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import { useState, useEffect } from "react";
import getLocalizedValue from "../../utils/getLocalizedValue";

export default function CustomeSelectDialog({
  open = false,
  setOpen,
  title = "",
  options = [],
  handleAddItems,
}) {
  //   const [value, setValue] = useState({});

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          textAlign="center"
          variant="h5"
          fontWeight="bold"
          color="primary"
        >
          {`Select ${title}`}
        </DialogTitle>
        <DialogContent style={{ height: "300px" }}>
          <Autocomplete
            id="fixed-tags-demo"
            size="small"
            onChange={(e, newValue) => {
              if (newValue) {
                handleAddItems(newValue);
              }
            }}
            options={options}
            getOptionLabel={(option) => getLocalizedValue(option.name)}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip
                    key={key}
                    label={getLocalizedValue(option.name)}
                    {...tagProps}
                  />
                );
              })
            }
            style={{ width: 500 }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select Item" />
            )}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
