import {
  Box,
  Button,
  Checkbox,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTable from "../../components/common/CustomTable";
import { useNavigate } from "react-router-dom";
import getLocalizedValue from "../../utils/getLocalizedValue";
import useMenu from "../../hooks/useMenu";
import useCategory from "../../hooks/useCategory";

// Columns definition
const columns = [
  { id: "id", label: <Checkbox />, align: "left" },
  { id: "icon", label: "Icon", align: "left" },
  { id: "name", label: "Specification List", align: "left" },
  { id: "action", label: "Action", align: "right" },
];

function ManuPage() {
  const navigate = useNavigate();
  const { fetchCategories, categories, loading, deleteCategory } =
    useCategory();
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    page: categories?.page - 1 || 0,
    limit: categories?.limit || 10,
  });
  const [pageData, setPageData] = useState({
    totalCount: categories?.totalCount || 0,
    totalPages: categories?.totalPages || 0,
  });
  const [selectedIds, setSelectedIds] = useState([]);
  const [search, setSearch] = useState("");

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };
  const handleSearch = () => {
    setPagination({ ...pagination, page: 0 });
    const payload = {
      page: 1,
      limit: pagination?.limit,
      search: search,
    };
    fetchCategories(payload);
  };

  useEffect(() => {
    const payload = {
      page: pagination?.page + 1,
      limit: pagination?.limit,
      search: "",
    };
    fetchCategories(payload);
  }, [pagination?.page, pagination?.limit]);
  useEffect(() => {
    if (categories?.categories?.length > 0) {
      setData(
        categories?.categories?.map((category) => ({
          id: (
            <Checkbox
              checked={selectedIds.includes(category._id)}
              onChange={() => handleCheckboxChange(category._id)}
            />
          ),
          icon: (
            <img
              src={category.iconImage}
              alt="icon"
              style={{ width: "40px", height: "40px" }}
            />
          ),
          name: getLocalizedValue(category.name),
          action: (
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                navigate(`/menu/${category?._id}`);
              }}
            >
              Edit
            </Button>
          ),
        }))
      );
    } else {
      setData([]);
    }
    setPageData({
      totalCount: categories?.totalCount || 0,
      totalPages: categories?.totalPages || 0,
    });
  }, [categories, handleCheckboxChange]);
  return (
    <div>
      <Paper elevation={0} sx={{ borderRadius: "0px", padding: "10px" }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          color="primary"
        >
          Category Menu List
        </Typography>
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "20px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px" }}
              size="large"
              onClick={() => navigate("/menu/create")}
            >
              Add Menu Item
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px" }}
              size="large"
            >
              Delete
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "20px",
            }}
          >
            <TextField
              fullWidth
              sx={{ mx: "5px" }}
              size="small"
              value={search}
              onChange={(e) => {
                e.preventDefault();
                setSearch(e.target.value);
              }}
            />

            <Button
              variant="contained"
              color="primary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px" }}
              size="medium"
              onClick={handleSearch}
            >
              Search
            </Button>
          </Box>
        </Container>
      </Paper>

      <Paper elevation={0} sx={{ borderRadius: "0px", mt: "20px" }}>
        <CustomTable
          data={data}
          columns={columns}
          loading={loading}
          pagination={{...pagination, ...pageData}}
          setPagination={setPagination}
        />
      </Paper>
    </div>
  );
}

export default ManuPage;
