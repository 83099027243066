import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useReport from "../../hooks/useReport";
import getLocalizedValue from "../../utils/getLocalizedValue";
import { formatTimestamp } from "../../utils/formatHelper";

function ReportView() {
  const { id } = useParams();
  const { report, getReportById, deleteReports, loading } = useReport();
  const [reportData, setReportData] = useState({});
  const handleDeleteReport = (reportId) => {
    if (window.confirm("Are you sure you want to delete this report?")) {
      deleteReports({ ids: [reportId] });
    }
  };

  useEffect(() => {
    if (id) {
      getReportById(id).then((res) => {
        if (res?.success) {
          setReportData(res?.data);
        }
      });
    }
  }, [id]);
  return (
    <div>
      <Paper elevation={0} sx={{ borderRadius: "0px", padding: "10px" }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          color="primary"
        >
          Report View
        </Typography>
      </Paper>
      <Box>
        <ReportComponent report={reportData} loading={loading} />
      </Box>
      <Paper elevation={0} style={{ padding: "20px" }}>
        <Typography color="primary" variant="body1" fontWeight="bold">
          {" "}
          Date & Time of Report
        </Typography>
        <Typography
          variant="body2"
          fontWeight="bold"
          style={{ marginBottom: "20px" }}
        >
          {formatTimestamp(reportData?.createdAt)}
        </Typography>
        <Typography color="primary" variant="body1" fontWeight="bold">
          Why are you Reporting this?
        </Typography>
        <Box sx={{ display: "flex", marginBottom: "20px" }}>
          {reportData?.reasons?.map((item) => (
            <Box bgcolor="primary.main" sx={{ backgroundColor: "primary" }}>
              <Typography variant="body2" fontWeight="bold">
                {" "}
                {item}
              </Typography>
            </Box>
          ))}
        </Box>
        <Typography color="primary" variant="body1" fontWeight="bold">
          Text Message
        </Typography>
        <Box>
          <Typography variant="body2" fontWeight="bold">
            {reportData?.textMessage}
          </Typography>
        </Box>
      </Paper>
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px" }}
              size="large"
              onClick={() => window.history.back()}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px" }}
              size="large"
              onClick={() => handleDeleteReport(reportData?._id)}
            >
              Delete Report
            </Button>
          </Box>
        </Container>
      </Paper>
    </div>
  );
}

const ReportComponent = ({ report }) => {
  return (
    <Grid container spacing={2} paddingY={2}>
      {/* Reported On Details */}
      <Grid item xs={12} md={7}>
        <Paper elevation={0} sx={{ padding: 2 }}>
          <Typography variant="h6" color="primary" gutterBottom>
            Reported On
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />

          <Grid container spacing={2}>
            {/* Image Section */}
            <Grid item xs={4}>
              <Avatar
                src={
                  report?.reportedOn?.type === "user"
                    ? report?.reportedOn?.profilePicture
                    : "https://via.placeholder.com/150" // Replace with ad image if available
                }
                alt={"#"}
                sx={{
                  width: "100%",
                  height: "auto",
                  maxWidth: "150px",
                  maxHeight: "150px",
                  borderRadius: 2,
                }}
                variant="square"
              />
            </Grid>

            {/* Details Section */}
            <Grid item xs={8} md={8}>
              {report?.reportType === "user" ? (
                <>
                  <Typography variant="h6">
                    {report?.reportedOn?.name}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Location:</strong> {report?.reportedOn?.location}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Total Ads:</strong> {report?.reportedOn?.totalAds}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Joined On:</strong> {report?.reportedOn?.joinedOn}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="h6">
                    {report?.reportedOn?.title}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Category:</strong>{" "}
                    {getLocalizedValue(report?.reportedOn?.category?.name)}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Posted On:</strong> {report?.reportedOn?.postedDate}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Location:</strong> {report?.reportedOn?.country}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {/* Reported By Details */}
      <Grid item xs={12} md={5}>
        <Paper elevation={0} sx={{ padding: 2 }}>
          <Typography variant="h6" color="primary" gutterBottom>
            Reported By
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Avatar
                src={
                  report?.reportedBy?.profilePicture ||
                  "https://via.placeholder.com/150"
                }
                alt={report?.reportedBy?.name}
                sx={{
                  width: "100%",
                  height: "auto",
                  maxWidth: "150px",
                  maxHeight: "150px",
                  borderRadius: 2,
                }}
                variant="square"
              />
            </Grid>

            <Grid item xs={7}>
              <Typography variant="h6">{report?.reportedBy?.name}</Typography>
              <Typography variant="body2">
                <strong>Joined On:</strong> {report?.reportedBy?.createdAt}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ReportView;
