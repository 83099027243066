import api from "../utils/API";

// Base URL for your API
export const authService = {
  login: async (payload) => {
    try {
      const response = await api.post("/admin/auth/login", payload);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
