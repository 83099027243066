import React, { createContext, useContext, useState } from 'react';

// Create a context for images
const ImageContext = createContext();

// Create a provider component
export const ImageProvider = ({ children }) => {
    const [images, setImages] = useState([]); // Store the images in state

    // Function to add images
    const addImages = (images) => {
        setImages(() => [...images]);
    };

    // Function to clear images
    const clearImages = () => {
        setImages([]);
    };

    return (
        <ImageContext.Provider value={{ images, addImages, clearImages }}>
            {children}
        </ImageContext.Provider>
    );
};

// Custom hook to use the ImageContext
export const useImageContext = () => {
    return useContext(ImageContext);
};
