import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import AdminLoginForm from "../../components/AdminLogin/AdminLoginForm";

const AdminLogin = () => {
  const theme = useTheme(); // Access the theme for colors

  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: theme.palette.primary.main,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
      }}
    >
      {/* Logo  Heading and Form */}
      <AdminLoginForm />

      {/* Footer Text */}
      <Box
        component="footer"
        sx={{
          position: "absolute",
          bottom: 20,
          textAlign: "center",
          width: "100%",
          color: "white",
        }}
      >
        <Typography variant="body2">
          &copy; 2024 Admin Panel. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default AdminLogin;
