import api from "../utils/API";

export const createPostService = {
    createPost: async (formData) => {
        const response = await api.post('/admin/ad',formData,   {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
     
        return response;
    },
    updatePost: async (id,formData) => {
        const response = await api.put(`/admin/ad/${id}`,formData,   {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
     
        return response;
    }
};
