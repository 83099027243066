import { createSlice } from "@reduxjs/toolkit";

// Create a slice for categories
const additionalDetailsSlice = createSlice({
  name: "additionaldetails",
  initialState: {
    items: [],
    menuItems: [],
    loading: false,
    error: null,
  },
  reducers: {
    setAdditionalDetails: (state, action) => {
      state.items = action.payload.data || [];
      state.loading = false;
    },
  },
});

export const selectadditionalDetails = (state) => state.additionaldetails?.items;
export const selectAdditionalDetailsLoading = (state) =>
  state.additionaldetails?.loading;
export const selectAdditionalDetailsError = (state) =>
  state.additionaldetails?.error;

// Export the reducer to include in the store
export const { setAdditionalDetails } = additionalDetailsSlice.actions;
export default additionalDetailsSlice.reducer;
