import { createSlice } from "@reduxjs/toolkit";

// Create a slice for categories
const userSlice = createSlice({
  name: "user",
  initialState: {
    userItems: [],
    user: {},
    loading: false,
    error: null,
  },
  reducers: {
    setUserItems: (state, action) => {
      state.userItems = action.payload.data || [];
      state.loading = false;
    },
    setUser: (state, action) => {
      state.user = action.payload.data || [];
      state.loading = false;
    },
  },
});

// Export the loading and error selectors if needed
export const selectUserLoading = (state) => state.user.loading;
export const selectUserError = (state) => state.user.error;
export const selectUserItems = (state) => state.user.userItems;
export const selectUser = (state) => state.user.user;

// Export the reducer to include in the store
export const { setUser, setUserItems } = userSlice.actions;
export default userSlice.reducer;
