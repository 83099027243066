import React from "react";
import { Box, Typography, Paper, Button, Link } from "@mui/material";
import useCreatePost from "../../hooks/useCreatePost";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UpdateConfirm = (props) => {
  const { t } = useTranslation();
  const { updatePost } = useCreatePost(); // Assuming you have an updatePost function
  const navigate = useNavigate();
  const { Id } = useParams();

 
  const handleUpdateConfirm = async () => {
    let data = await updatePost(Id);
    if (data.success) {
      navigate("/create/sucess"); // Redirect to an update success page
    }else{
      navigate("/create/error"); // Redirect to an update success page
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      {/* Heading */}
      <Typography
        variant="h5"
        sx={{
          color: "primary.main",
          fontWeight: "bold",
          mb: 4,
          textAlign: "center",
        }}
      >
        {t("CONFIRM_UPDATE")} {/* Update heading */}
      </Typography>

      <Box
        sx={{ padding: 3, backgroundColor: "background.form", marginBottom: 4 }}
      >

        {/* Terms and Privacy Policy */}
        <Box textAlign="center">
          <Typography variant="body2" sx={{ mb: 2 }}>
            By proceeding, you confirm the updates and agree to our{" "}
            <Link
              href="/terms"
              target="_blank"
              sx={{ color: "primary.main", fontWeight: "bold" }}
            >
              {t("TERMS_AND_CONDITIONS")}
            </Link>{" "}
            and{" "}
            <Link
              href="/privacy"
              target="_blank"
              sx={{ color: "primary.main", fontWeight: "bold" }}
            >
              {t("PRIVACY_POLICY")}
            </Link>
            .
          </Typography>
        </Box>
      </Box>
      {/* Confirm Update Button */}
      <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ padding: "10px 20px", fontWeight: "bold", margin: "0px auto" }}
          onClick={handleUpdateConfirm} // Call handleUpdateConfirm function
        >
          {t("CONFIRM_UPDATE")} {/* Update button text */}
        </Button>
      </Box>
    </Box>
  );
};

export default UpdateConfirm;
