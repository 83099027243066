import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { additionalDetailsService } from "../services/additionalDetails.api";
import {
  selectadditionalDetails,
  selectAdditionalDetailsError,
  setAdditionalDetails,
} from "../store/additionalDetailsSlice";

const useAdditionalDetails = () => {
  const dispatch = useDispatch();
  const additionalDetails = useSelector(selectadditionalDetails);

  const error = useSelector(selectAdditionalDetailsError);
  const [loading, setLoading] = useState(false);

  const getAdditionalDetails = async (payload) => {
    setLoading(true);
    const data = await additionalDetailsService.fetchAdditionalDetails(payload);
    if (data.success) {
      dispatch(setAdditionalDetails(data));
    }
    setLoading(false);
    return data;
  };

  const createAdditionalDetails = async (payload) => {
    setLoading(true);
    const data = await additionalDetailsService.createAdditionalDetails(
      payload
    );
    setLoading(false);
    return data;
  };

  const updateAdditionalDetails = async (id, payload) => {
    setLoading(true);
    const data = await additionalDetailsService.updateAdditionalDetails(
      id,
      payload
    );
    setLoading(false);
    return data;
  };

  const deleteAdditionalDetails = async (payload) => {
    setLoading(true);
    const data = await additionalDetailsService.deleteAdditionalDetails(
      payload
    );
    setLoading(false);
    return data;
  };

  const getAdditionalDetailsById = async (payload) => {
    setLoading(true);
    const data = await additionalDetailsService.fetchAdditionalDetailsById(
      payload
    );
    setLoading(false);
    return data;
  };

  return {
    getAdditionalDetails,
    additionalDetails,
    createAdditionalDetails,
    updateAdditionalDetails,
    deleteAdditionalDetails,
    getAdditionalDetailsById,
    loading,
    error,
  };
};

export default useAdditionalDetails;
