import React from "react";
import { Box, Typography, Paper, Button, Link } from "@mui/material";
import useCreatePost from "../../hooks/useCreatePost";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ConfirmPayment = (props) => {
  const { t } = useTranslation();
  const { stepData, createPost } = useCreatePost();
  const navigate = useNavigate();
  const { paymentPlan } = stepData;

  const handleConfirmPayment = async () => {
    let data = await createPost();
    if (data.success) {
      navigate("/create/sucess");
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      {/* Heading */}
      <Typography
        variant="h5"
        sx={{
          color: "primary.main",
          fontWeight: "bold",
          mb: 4,
          textAlign: "center",
        }}
      >
        {t("CONFIRM_PAYMENT")}
      </Typography>

      <Box
        sx={{ padding: 3, backgroundColor: "background.form", marginBottom: 4 }}
      >
        {/* Plan Preview */}
        <Box
          elevation={3}
          sx={{
            padding: 2,
            mb: 4,
            borderRadius: "5px",
            backgroundColor: "background.white",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {paymentPlan.description}
          </Typography>
          {paymentPlan.description && (
            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
              {paymentPlan.description}
            </Typography>
          )}
          <Typography
            variant="h5"
            sx={{ color: "primary.main", fontWeight: "bold" }}
          >
            {paymentPlan.price}
          </Typography>
        </Box>

        {/* Payment Summary */}
        <Box
          sx={{
            marginBottom: 4,
            p: 2,
            backgroundColor: "background.white",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("TOTAL")}:{" "}
          </Typography>
          <Typography
            variant="h5"
            sx={{ color: "primary.main", fontWeight: "bold" }}
          >
            {paymentPlan.price} KWD
          </Typography>
        </Box>

        {/* Terms and Privacy Policy */}
        <Box textAlign="center">
          <Typography variant="body2" sx={{ mb: 2 }}>
            By proceeding, you agree to our{" "}
            <Link
              href="/terms"
              target="_blank"
              sx={{ color: "primary.main", fontWeight: "bold" }}
            >
              {t("TERMS_AND_CONDITIONS")}
            </Link>{" "}
            and{" "}
            <Link
              href="/privacy"
              target="_blank"
              sx={{ color: "primary.main", fontWeight: "bold" }}
            >
              {t("PRIVACY_POLICY")}
            </Link>
            .
          </Typography>
        </Box>
      </Box>
      {/* Confirm Payment Button */}
      <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ padding: "10px 20px", fontWeight: "bold", margin: "0px auto" }}
          onClick={handleConfirmPayment}
        >
          {t("CONFIRM_PAYMENT")}
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmPayment;
