import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  CircularProgress,
} from "@mui/material";

const CustomTable = ({
  data,
  columns,
  loading,
  pagination,
  setPagination,
  hidePagination,
}) => {
  const handleChangePage = (event, newPage) => {
    setPagination({
      ...pagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination({
      ...pagination,
      page: 0,
      limit: parseInt(event.target.value, 10),
    });
  };

  return (
    <Box sx={{ width: "100%", marginTop: 3 }}>
      <Table stickyHeader aria-label="custom table" sx={{ minWidth: 750 }}>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                sx={{
                  padding: "10px",
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "primary.main",
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {!loading && (
          <TableBody>
            {data?.map((row, index) => (
              <TableRow hover key={index}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell
                      style={{ padding: "10px" }}
                      key={column.id}
                      align={column.align}
                    >
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>

      {loading && (
        <div
          style={{
            minHeight: "250px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={24} />
        </div>
      )}

      {/* Pagination Controls */}
      {!hidePagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 25]}
          component="div"
          count={pagination?.totalCount}
          rowsPerPage={pagination?.limit || 10}
          page={pagination?.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} of ${count}`
          }
        />
      )}
      {/* Item Overview */}
      {!hidePagination && (
        <Typography sx={{ mt: 2, textAlign: "right" }} variant="body2">
          {`Showing ${pagination?.page * pagination?.limit + 1 || 0}-${
            Math.min(
              pagination?.page * pagination?.limit + pagination?.limit,
              pagination?.totalCount
            ) || 0
          } of ${pagination?.totalCount || 0} items`}
        </Typography>
      )}
    </Box>
  );
};

export default CustomTable;
