import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reportService } from "../services/report.api";
import {
  selectReport,
  selectReportError,
  selectReportItems,
  setReport,
  setReportItems,
} from "../store/reportSlice";

const useReport = () => {
  const dispatch = useDispatch();
  const reports = useSelector(selectReportItems);
  const report = useSelector(selectReport);
  const error = useSelector(selectReportError);
  const [loading, setLoading] = useState(false);

  const getReportList = async (payload) => {
    setLoading(true);
    const data = await reportService.fetchReportItems(payload);
    if (data.success) {
      dispatch(setReportItems(data));
    }
    setLoading(false);
    return data;
  };

  const getReportById = async (id) => {
    setLoading(true);
    const data = await reportService.fetchReportById(id);
    if (data.success) {
      dispatch(setReport(data));
    }
    setLoading(false);
    return data;
  };

  const deleteReports = async (payload) => {
    setLoading(true);
    const data = await reportService.deleteReports(payload);
    setLoading(false);
    return data;
  };

  return {
    getReportList,
    reports,
    getReportById,
    deleteReports,
    report,
    loading,
    error,
  };
};

export default useReport;
