// src/components/EmptyChatPlaceholder.js
import { Box, Typography } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

export default function EmptyChatPlaceholder() {
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'text.secondary',
        backgroundColor:'background.white',
        textAlign: 'center',
        padding: 4,
      }}
    >
      <ChatBubbleOutlineIcon sx={{ fontSize: 80, mb: 2 }} />
      <Typography variant="h6" gutterBottom>
        No Chat Selected
      </Typography>
      <Typography variant="body2">
        Select a conversation from the list to start chatting.
      </Typography>
    </Box>
  );
}
